import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import SerialError from '../../../../assets/insurance/serial-error.png';
import SerialError2 from '../../../../assets/insurance/serial-error@2x.png';
import SerialError3 from '../../../../assets/insurance/serial-error@3x.png';
import SearchImage from '../../../../assets/insurance/SearchIcon.png';
import SearchImage2 from '../../../../assets/insurance/SearchIcon@2x.png';
import SearchImage3 from '../../../../assets/insurance/SearchIcon@3x.png';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import SuccessIcon from '../../../../assets/insurance/SuccessCheckmarkCircle.png';
import SuccessIcon2 from '../../../../assets/insurance/SuccessCheckmarkCircle@2x.png';
import SuccessIcon3 from '../../../../assets/insurance/SuccessCheckmarkCircle@3x.png';
import GoldSheild from '../../../../assets/insurance/gold-sheild.png';
import GoldSheild2 from '../../../../assets/insurance/gold-sheild@2x.png';
import GoldSheild3 from '../../../../assets/insurance/gold-sheild@3x.png';
import PlatSheild from '../../../../assets/insurance/plat-sheild.png';
import PlatSheild2 from '../../../../assets/insurance/plat-sheild@2x.png';
import PlatSheild3 from '../../../../assets/insurance/plat-sheild@3x.png';
import InsureProductProcessContext from '../../../../../lib/contexts/InsureProductProcessContext';
import Pusher from 'pusher-js';
import {
  EmbeddedInsuranceService,
  Item,
  Quote,
} from '@zenown-insurance/services/requests';
import PrimaryButton from '../../../../../lib/components/primary-button/PrimaryButton';
import ReturnButton from './../../../../components/return-button/ReturnButton';
import { init } from 'i18next';
export function SurveryScreen(props: any) {
  const {
    setIsLoading,
    quote,
    tProcessing: t,
    setIntialPremium,
    initialPremium,
    setCurrentProcessStep,
    setSurveyAnswers,
    trackEvent,
    setQuote,
  } = useContext(InsureProductProcessContext);
  const [finishedOwnerSurvey, setFinishedOwnerSurvey] = useState(false);
  const suerveyQuestions = [
    {
      question: t('survey.1.question'),
      answerOptions: [t('survey.1.option.1'), t('survey.1.option.2')],
    },
    {
      question: t('survey.2.question'),
      answerOptions: [t('survey.2.option.1'), t('survey.2.option.2')],
    },
  ];
  const finishSurvey = () => {
    if (
      !quote &&
      localStorage.getItem('currentProcessStep') !== 'quoteProposition'
    ) {
      setCurrentProcessStep('processing');
      localStorage.setItem('currentProcessStep', 'processing');
    } else {
      setCurrentProcessStep('quoteProposition');
      localStorage.setItem('currentProcessStep', 'quoteProposition');
    }
  };
  const surveryResults = [
    {
      title: t('survey.result.1.title'),
      subTitle: t('survey.result.1.subtitle'),
      pack: t('survey.result.1.pack'),
      submit: t('survey.result.1.submit'),
      onClick: finishSurvey,
    },
    {
      title: t('survey.result.2.title'),
      subTitle: t('survey.result.2.subtitle'),
      pack: t('survey.result.2.pack'),
      submit: t('survey.result.2.submit'),
      onClick: finishSurvey,
    },
    {
      title: t('survey.result.3.title'),
      subTitle: t('survey.result.3.subtitle'),
      submit: t('survey.result.3.submit'),
      onClick: finishSurvey,
    },
  ];
  const [answers, setAnswers] = useState<number[]>([]);

  const [step, setStep] = useState(0);
  const [result, setResult] = useState(0);
  useEffect(() => {
    if (answers.length < 2) {
      trackEvent({
        event: 'Page Visit',
        action: 'Question' + (answers.length + 3),
      });
      setStep(answers.length);
    } else {
      setSurveyAnswers(
        answers
          .map((n: number) => {
            if (n === 1) return 'N';
            else return 'Y';
          })
          .join('')
      );
      localStorage.setItem(
        'surveyAnswers',
        answers
          .map((n: number) => {
            if (n === 1) return 'N';
            else return 'Y';
          })
          .join('')
      );
      let res = 'Gold';
      if (answers[0] === 0 && answers[1] === 1) {
        setResult(0);

        setIntialPremium(false);
        if (quote?.uuid) {
          setQuote({ ...quote, premium: false });

          localStorage.setItem('intialPremium', 'false');
        }
      } else if (answers[0] === 1 && answers[1] === 1) {
        setResult(2);

        setIntialPremium(false);
        if (quote?.uuid) {
          setQuote({ ...quote, premium: false });
        }
        localStorage.setItem('intialPremium', 'false');
      } else {
        setResult(1);

        setIntialPremium(true);
        if (quote?.uuid) {
          setQuote({ ...quote, premium: true });
        }
        localStorage.setItem('intialPremium', 'true');

        res = 'Platinum';
      }
      trackEvent({
        event: 'Page Visit',
        action: res + ' response',
      });
      /*setTimeout(() => {
        surveryResults[result].onClick();
      }, 2300);*/
    }
  }, [answers]);
  if (!finishedOwnerSurvey)
    return (
      <SurveryOwnerScreen
        finishOwnerSurvery={() => setFinishedOwnerSurvey(true)}
      />
    );
  if (answers.length < 2) {
    return (
      <>
        <div
          style={{
            width: '100%',
            height: '100vh',
            paddingTop: '126px',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '21px',
              color: '#0179FF',
            }}
          >
            {t('survey.title')}
          </Typography>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '22px',
              lineHeight: '28px',
              color: '#383A49',
              marginTop: '24px',
              marginBottom: '77px',
            }}
          >
            {suerveyQuestions[step].question}
          </Typography>
          {suerveyQuestions[step].answerOptions.map((answer, i) => (
            <>
              <Button
                variant="text"
                key={i}
                sx={{
                  paddingTop: '17px',
                  paddingBottom: '17px',
                  width: '100%',
                  height: '55px',
                  textTransform: 'none',
                  border: '1px solid #CDCFD5',
                  marginBottom: i === 0 ? '24px' : '0px',
                }}
                onClick={() => {
                  trackEvent({
                    event: 'Button click',
                    action: i === 0 ? 'Yes' : 'No',
                  });
                  setTimeout(() => setAnswers([...answers, i]), 400);
                }}
              >
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#383A49',
                  }}
                >
                  {answer}
                </Typography>
              </Button>
            </>
          ))}
          {false && (
            <div
              style={{
                position: 'fixed',
                bottom: '0px',
                width: 'calc(100% - 30px)',
                marginBottom: '32px',
                left: '15px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: '#9CA0AB',
                }}
              >
                {t('survey.question') + ' ' + (step + 1)}
                {'/' + suerveyQuestions[step]?.answerOptions?.length}
              </Typography>
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          style={{
            width: '100%',
            height: '100vh',
            // paddingTop: '128px',

            display: 'flex',
            justifyContent: 'flex-end',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <img
                src={SuccessIcon}
                srcSet={`${SuccessIcon} 1x, ${SuccessIcon2} 2x, ${SuccessIcon3} 3x`}
                style={{
                  width: '67px',
                  height: '67px',
                  marginBottom: '24px',
                }}
              />
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '22px',
                  lineHeight: '28px',
                  color: '#383A49',
                  marginBottom: '16px',
                }}
              >
                {surveryResults[result].title}
              </Typography>
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: '#383A49',
                  marginBottom: '32px',
                }}
              >
                {surveryResults[result].subTitle}
              </Typography>
              {surveryResults[result].pack && (
                <div
                  style={{
                    backgroundColor: result === 0 ? '#FEFAED' : '#F4F4F4',

                    marginInline: 'auto',

                    maxWidth: '256px',
                    maxHeight: '64px',
                    minWidth: '256px',
                    minHeight: '64px',

                    borderRadius: '10px',
                  }}
                >
                  <div
                    style={{
                      padding: '16px 32px',
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <img
                      src={result === 0 ? GoldSheild : PlatSheild}
                      srcSet={`${result === 0 ? GoldSheild : PlatSheild} 1x, ${
                        result === 0 ? GoldSheild2 : PlatSheild2
                      } 2x, ${result === 0 ? GoldSheild3 : PlatSheild3} 3x`}
                      style={{
                        width: '32px',
                        height: '32px',
                        marginRight: '16px',
                      }}
                    />
                    <Typography
                      sx={{
                        marginTop: '4px',
                        fontWeight: '600',
                        fontSize: '17px',
                        lineHeight: '23.22px',
                        color: result === 0 ? '#C6B997' : '#8A8690',
                      }}
                    >
                      {surveryResults[result].pack}
                    </Typography>
                  </div>{' '}
                </div>
              )}
            </div>{' '}
          </div>

          <div style={{ width: '100%', marginBottom: '56px' }}>
            <Button
              variant="contained"
              sx={{
                paddingTop: '17px',
                paddingBottom: '17px',
                width: '100%',
                height: '55px',
                textTransform: 'none',
                borderRadius: '10px',
              }}
              onClick={() => {
                setIsLoading(true);
                trackEvent({
                  event: 'Button click',
                  action: 'Get your quote',
                });
                setTimeout(() => {
                  surveryResults[result].onClick();
                  setIsLoading(false);
                }, 500);
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: 'white',
                }}
              >
                {surveryResults[result].submit}
              </Typography>
            </Button>
          </div>
        </div>
      </>
    );
  }
}
export function SurveryOwnerScreen(props: any) {
  const {
    setIsLoading,
    isLoading,
    InitProcess,
    quote,
    tProcessing: t,
    tUpload,
    setIntialPremium,
    setCurrentProcessStep,
    setSurveyAnswers,
    trackEvent,
  } = useContext(InsureProductProcessContext);
  const { finishOwnerSurvery } = props;
  const [phoneNumber, setPhoneNumber] = useState('+49 ');
  const suerveyQuestions = [
    {
      question: t('survey.owner.1.question'),
      answerOptions: [
        t('survey.owner.1.option.1'),
        t('survey.owner.1.option.2'),
      ],
    },
    {
      question: t('survey.owner.2.question'),
      answerOptions: [
        t('survey.owner.2.option.1'),
        t('survey.owner.2.option.2'),
        t('survey.owner.2.option.3'),
      ],
    },
  ];

  const surveryResults = [
    {
      img: (
        <img
          src={SearchImage}
          srcSet={`${SearchImage} 1x, ${SearchImage2} 2x, ${SearchImage3} 3x`}
          style={{
            width: '105px',
            height: '111px',
            marginBottom: '63px',
          }}
          alt="SearchIcon"
        />
      ),
      title: t('survey.owner.result.1.title'),
      subTitle: (
        <>
          {t('survey.owner.result.1.subtitle')}
          <br /> <br />
          <TextField
            sx={{ minWidth: '250px', width: '100%' }}
            defaultValue={phoneNumber}
            label={tUpload('support.phonenumber.label')}
            fullWidth
            value={phoneNumber}
            onChange={(event) => {
              trackEvent({
                event: 'Input',
                action: 'Phone number',
              });
              setPhoneNumber(event.target.value);
            }}
          />
        </>
      ),
      submit: t('survey.owner.result.1.submit'),
      cancel: t('survey.owner.result.1.cancel'),
      onClick: async () => {
        try {
          trackEvent({
            event: 'Button click',
            action: 'submit phone number',
          });
          setIsLoading(true);
          const embeddedInsuranceService = new EmbeddedInsuranceService();
          await embeddedInsuranceService.notifyPhoneNumber(phoneNumber);
        } catch (err) {
        } finally {
          setResult(1);
          setIsLoading(false);
        }
      },
      onCancel: async () => {
        await InitProcess();
      },
    },
    {
      img: (
        <img
          src={SuccessIcon}
          srcSet={`${SuccessIcon} 1x, ${SuccessIcon2} 2x, ${SuccessIcon3} 3x`}
          style={{
            width: '67px',
            height: '67px',
            marginBottom: '24px',
          }}
        />
      ),
      title: t('survey.owner.result.2.title'),
      subTitle: <>{t('survey.owner.result.2.subtitle')}</>,
      submit: t('survey.owner.result.2.submit'),
      onClick: async () => {
        await InitProcess();
      },
      onCancel: async () => {
        await InitProcess();
      },
    },
    {
      img: (
        <img
          src={SerialError}
          srcSet={`${SerialError} 1x, ${SerialError2} 2x, ${SerialError3} 3x`}
          style={{
            width: '70.24px',
            height: '70.24px',
            marginBottom: '24px',
          }}
          alt=""
        />
      ),
      title: t('survey.owner.result.3.title'),
      subTitle: <>{t('survey.owner.result.3.subtitle')}</>,
      submit: t('survey.owner.result.3.submit'),
      onClick: async () => {
        await InitProcess();
      },
    },
  ];
  const [answers, setAnswers] = useState<number[]>([]);

  const [step, setStep] = useState(0);
  const [result, setResult] = useState(0);
  useEffect(() => {
    if (answers.length < 2) {
      trackEvent({
        event: 'Page Visit',
        action: 'Question ' + (answers.length + 1),
      });
      setStep(answers.length);
    } else {
      if (answers[1] === 0) {
        finishOwnerSurvery();
      } else {
        if (answers[1] === 1) {
          setResult(2);
          //failed
        } else if (answers[1] === 2) {
          setResult(0);
          //support
        }
      }
    }
  }, [answers]);

  if (answers.length < 2) {
    return (
      <>
        <div
          style={{
            width: '100%',
            height: '100vh',
            paddingTop: '126px',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '21px',
              color: '#0179FF',
            }}
          >
            {t('survey.title')}
          </Typography>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '22px',
              lineHeight: '28px',
              color: '#383A49',
              marginTop: '24px',
              marginBottom: '77px',
            }}
          >
            {suerveyQuestions[step].question}
          </Typography>
          {suerveyQuestions[step].answerOptions.map((answer, i) => (
            <>
              <Button
                variant="text"
                key={i}
                sx={{
                  paddingTop: '17px',
                  paddingBottom: '17px',
                  width: '100%',
                  height: '55px',
                  textTransform: 'none',
                  border: '1px solid #CDCFD5',
                  marginBottom: i <= 1 ? '24px' : '0px',
                }}
                onClick={() => {
                  trackEvent({
                    event: 'Button click',
                    action: i === 0 ? 'Yes' : i === 1 ? 'No' : 'Maybe',
                  });
                  if (i === 1) {
                    trackEvent({
                      event: 'Page Visit',
                      action: "can't answer",
                    });
                  }
                  if (i === 2) {
                    trackEvent({
                      event: 'Page Visit',
                      action: 'Support page',
                    });
                  }
                  setTimeout(() => setAnswers([...answers, i]), 400);
                }}
              >
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#383A49',
                  }}
                >
                  {answer}
                </Typography>
              </Button>
            </>
          ))}
          {false && (
            <div
              style={{
                position: 'fixed',
                bottom: '0px',
                width: 'calc(100% - 30px)',
                marginBottom: '32px',
                left: '15px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: '#9CA0AB',
                }}
              >
                {t('survey.question') + ' ' + (step + 1)}
                {'/' + suerveyQuestions[step]?.answerOptions?.length}
              </Typography>
            </div>
          )}
          {false && (
            <div
              style={{
                position: 'fixed',
                bottom: '0px',
                width: 'calc(100% - 30px)',
                marginBottom: '32px',
                left: '15px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: '#9CA0AB',
                }}
              >
                {t('survey.question') + ' ' + (step + 1)}
                {'/' + suerveyQuestions[step]?.answerOptions?.length}
              </Typography>
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          style={{
            width: '100%',
            height: '100vh',
            // paddingTop: '128px',

            display: 'flex',
            justifyContent: 'flex-end',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '100%', marginInline: 'auto' }}>
              {surveryResults[result].img}
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '22px',
                  lineHeight: '28px',
                  color: '#383A49',
                  marginBottom: '16px',
                }}
              >
                {surveryResults[result].title}
              </Typography>
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: '#383A49',
                  marginBottom: '32px',
                }}
              >
                {surveryResults[result].subTitle}
              </Typography>
            </div>{' '}
          </div>

          <div
            style={{
              width: '100%',
              marginBottom:
                surveryResults[result].cancel && surveryResults[result].onCancel
                  ? '20px'
                  : '56px',
            }}
          >
            <Button
              variant="contained"
              sx={{
                paddingTop: '17px',
                paddingBottom: '17px',
                width: '100%',
                height: '55px',
                textTransform: 'none',
                borderRadius: '10px',
                pointerEvents: isLoading ? 'none' : 'all',
              }}
              onClick={() => {
                setIsLoading(true);
                trackEvent({
                  event: 'Button click',
                  action: 'Get your quote',
                });
                setTimeout(() => {
                  surveryResults[result].onClick();
                  setIsLoading(false);
                }, 500);
              }}
            >
              {' '}
              {isLoading && (
                <CircularProgress
                  size="20px"
                  sx={{ color: 'white', marginRight: '7px' }}
                />
              )}
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: 'white',
                }}
              >
                {surveryResults[result].submit}
              </Typography>
            </Button>
          </div>
          {Boolean(
            surveryResults[result].cancel && surveryResults[result].onCancel
          ) && (
            <div style={{ width: '100%', marginBottom: '56px' }}>
              <Button
                variant="text"
                sx={{
                  paddingTop: '17px',
                  paddingBottom: '17px',
                  width: '100%',
                  height: '55px',
                  textTransform: 'none',
                  borderRadius: '10px',
                }}
                onClick={() => {
                  setIsLoading(true);
                  trackEvent({
                    event: 'Button click',
                    action: 'Get your quote',
                  });
                  setTimeout(() => {
                    if (
                      surveryResults &&
                      surveryResults[result] &&
                      surveryResults[result]?.onCancel
                    )
                      //@ts-ignore
                      surveryResults[result].onCancel();
                    setIsLoading(false);
                  }, 500);
                }}
              >
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '21px',
                  }}
                >
                  {surveryResults[result].cancel}
                </Typography>
              </Button>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default SurveryScreen;
