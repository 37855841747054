import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import InsureProductProcessContext from '../../contexts/InsureProductProcessContext';
export type ReturnButtonProps = {
  onClick: () => void;
  close?: boolean;
  style?: any;
};

export function ReturnButton(props: ReturnButtonProps) {
  return (
    <Button
      variant={'outlined'}
      style={
        props.style
          ? {
              borderColor: 'rgba(68,71,74,0.06)',
              minHeight: 32,
              minWidth: 32,
              padding: 0,
              color: '#383A49',
              cursor: 'pointer',
              ...props.style,
            }
          : {
              borderColor: 'rgba(68,71,74,0.06)',
              minHeight: 32,
              minWidth: 32,
              padding: 0,
              color: '#383A49',
              position: 'absolute',
              left: '0px',
              top: '-50px',
              cursor: 'pointer',
            }
      }
      onClick={() => {
        props.onClick();
      }}
    >
      {props.close ? (
        <CloseIcon sx={{ cursor: 'pointer' }} />
      ) : (
        <ArrowBackIcon sx={{ cursor: 'pointer' }} />
      )}
    </Button>
  );
}

export default ReturnButton;
