import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { presets } from 'react-text-transition';

import SearchImage from '../../../../assets/insurance/SearchIcon.png';
import SearchImage2 from '../../../../assets/insurance/SearchIcon@2x.png';
import SearchImage3 from '../../../../assets/insurance/SearchIcon@3x.png';
import Transionner from '../../../../components/Transionner/Transionner';
import InsuranceTypes from '../../../../components/zenown-insurance/InsuranceTypes';
import InsureProductProcessContext from '../../../../../lib/contexts/InsureProductProcessContext';
import Pusher from 'pusher-js';
import { Item, Quote } from '@zenown-insurance/services/requests';
import PrimaryButton from '../../../../../lib/components/primary-button/PrimaryButton';
import ReturnButton from './../../../../components/return-button/ReturnButton';
export function ProcessingInsuranceRequest(props: any) {
  const {
    tProcessing: t,
    trackEvent,
    setOpenConfirmationPopUP,
    setOutWorkHours,
  } = useContext(InsureProductProcessContext);
  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem('currentProcessStep') === 'processing')
        setOutWorkHours(true);
    }, 1000 * 30);
  }, []);
  const LoadingMessages = [1, 2, 3].map((x) => 'steps.step' + x);

  const [loadingStep, setLoadingStep] = useState<any>();
  const [started, setStarted] = useState();
  const [showClose, setShowClose] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShowClose(true);
    }, 10 * 1000);
    trackEvent({
      event: 'Page Visit',
      action: 'Almost there',
    });
  }, []);
  useEffect(() => {
    if (!loadingStep && !(loadingStep === 0)) {
      setLoadingStep(1);
    } else
      setTimeout(() => {
        setLoadingStep(loadingStep >= 2 ? 0 : loadingStep + 1);
      }, 2500);
  }, [loadingStep]);

  return (
    <div style={{}}>
      {showClose ? (
        <ReturnButton
          close={true}
          style={{
            position: 'absolute',

            marginTop: '20px',
            marginRight: '20px',
            right: '0',
          }}
          onClick={() => {
            trackEvent({
              event: 'Button click',
              action: 'Back',
            });
            setOpenConfirmationPopUP(true);
          }}
        />
      ) : (
        <></>
      )}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          minWidth: '100% !important',
          minHeight: '100% !important',
          padding: '0px !important',
        }}
      >
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
          sx={{
            marginTop: '71px',
            minWidth: '100%',
            padding: '0px !important',
          }}
        >
          <Grid item>
            <img
              src={SearchImage}
              srcSet={`${SearchImage} 1x, ${SearchImage2} 2x, ${SearchImage3} 3x`}
              style={{
                width: '105px',
                height: '111px',
              }}
              alt="SearchIcon"
            />
          </Grid>
          <Grid item>
            {' '}
            <Transionner
              style={{ display: 'block', margin: 'auto' }}
              direction="up"
              delay={3000}
              children={
                <Typography variant="body1">
                  {console.log(loadingStep)}
                  {console.log(t(LoadingMessages[loadingStep]))}
                  {t(LoadingMessages[loadingStep || 0])} ...
                </Typography>
              }
              springConfig={presets.gentle}
              inline
            />
          </Grid>
        </Grid>
        <Grid
          sx={{
            minWidth: '100%',
            padding: '0px !important',
            marginBottom: '71px',
            marginTop: '97px',
            gap: '20px',
          }}
          item
          container
          direction="column"
          justifyContent="space-around"
          alignItems="flex-start"
        >
          <Grid item>
            {' '}
            <Typography variant="h5">{t('coverText')}</Typography>
          </Grid>
          <Grid item>
            <InsuranceTypes />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ProcessingInsuranceRequest;
