import { Trans, useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import {
  Grid,
  Typography,
  Box,
  Card,
  Link,
  Divider,
  Checkbox,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  Paper,
  List,
  ListItemSecondaryAction,
} from '@mui/material';
import { getPriceFormatted } from '@zenown-insurance/services/requests';
import ReturnButton from './../../../../components/return-button/ReturnButton';
import BikeIcon from './../../../../assets/insurance/bike-icon.svg';
import SheildIcon from './../../../../assets/insurance/sheild-icon.svg';
import ZenOwnLogo from '../../../../assets/insurance/ZenOwn.png';
import ZenOwnLogo2 from '../../../../assets/insurance/ZenOwn@2x.png';
import ZenOwnLogo3 from '../../../../assets/insurance/ZenOwn@3x.png';
import ElementLogo from '../../../../assets/insurance/element-logos.png';
import ElementLogo2 from '../../../../assets/insurance/element-logos@2x.png';
import ElementLogo3 from '../../../../assets/insurance/element-logos@3x.png';
import RVLogo from '../../../../assets/insurance/RV.png';
import RVLogo2 from '../../../../assets/insurance/RV@2x.png';
import RVLogo3 from '../../../../assets/insurance/RV@3x.png';
import SuccessIcon from '../../../../assets/insurance/success.png';
import SuccessIcon2 from '../../../../assets/insurance/success@2x.png';
import SuccessIcon3 from '../../../../assets/insurance/success@3x.png';

import PrimaryButton from '../../../../../lib/components/primary-button/PrimaryButton';
import SecondaryButton from '../../../../../lib/components/secondary-button/SecondaryButton';
import { useContext, useEffect, useState } from 'react';
import { LogoHeader } from '../../../../../lib/components/zenown-insurance';
import InsureProductProcessContext from '../../../../../lib/contexts/InsureProductProcessContext';
import moment from 'moment';
import { getCurrencyText } from '@zenown-insurance/services/requests';
import { LongFooter } from 'libs/ui/src/lib/components/zenown-insurance/LongFooter';

export function PayementCompleted(props: any) {
  const {
    InitProcess,
    setOpenConfirmationPopUP,
    quote,
    item,
    tcommon,
    trackEvent,
    tPayementCompleted: t,
  } = useContext(InsureProductProcessContext);
  useEffect(() => {
    trackEvent({
      event: 'Page Visit',
      action: 'Confirmation page',
    });
  }, []);

  const quoteData = [
    {
      label: t('productName') + ':',
      value: item ? (item?.brand ? item?.brand + ' ' : '') + item?.name : '',
    },
    {
      label: t('productPrice') + ':',
      value: getPriceFormatted(item?.price?.toFixed(2)),
    },
    {
      label: t('InsurancePrice') + ':',
      value: quote?.premium
        ? getPriceFormatted(quote?.price + quote?.premiumPrice)
        : getPriceFormatted(quote?.price),
    },
    { label: t('InsuranceStart') + ':', value: moment().format('DD.MM.YYYY') },
    {
      label: t('InsuranceEnds') + ':',
      value: moment().add(1, 'y').format('DD.MM.YYYY'),
    },
  ];

  const finishProcess = () => {
    trackEvent({
      event: 'Button click',
      action: 'Done',
    });
    InitProcess();
  };

  return (
    <div style={{ position: 'relative' }}>
      <Grid
        sx={{ paddingBottom: '0px', gap: '30px' }}
        container
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        textAlign="center"
      >
        <Grid
          item
          textAlign={'center'}
          sx={{ marginTop: '15px' }}
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <img
              src={ElementLogo}
              srcSet={`${ElementLogo} 1x, ${ElementLogo2} 2x, ${ElementLogo3} 3x`}
              style={{
                width: '252px',
                height: '24px',
              }}
            />
          </Grid>
          <Grid item>
            {false && (
              <img
                src={RVLogo}
                srcSet={`${RVLogo} 1x, ${RVLogo2} 2x, ${RVLogo3} 3x`}
                style={{
                  height: '23px',
                  width: '56px',
                }}
              />
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          textAlign={'center'}
          sx={{ gap: '0px' }}
        >
          <Grid item>
            <img
              src={SuccessIcon}
              srcSet={`${SuccessIcon} 1x, ${SuccessIcon2} 2x, ${SuccessIcon3} 3x`}
              style={{ width: '42px', height: '42px' }}
            />
            <Typography
              variant="h1"
              sx={{ marginInline: '24px', paddingInline: '15px' }}
            >
              {t('title')}
            </Typography>
            <Typography
              variant="h3"
              color={'#9CA0AB'}
              sx={{ marginTop: '15px', fontWeight: '400' }}
            >
              {t('subTitle') + item?.name + ' ' + t('bike')}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            spacing={1}
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Grid item>
              <Typography
                variant="h5"
                color={'#9CA0AB'}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginTop: '30px',
                }}
              >
                {t('orderDetails') + ' '}:
              </Typography>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  minHeight: '245px',
                  maxHeight: '245px',
                  height: 'auto',
                  backgroundColor: '#F6F6F7',
                  borderRadius: '10px',
                  paddingTop: '1px',
                  paddingBottom: '21px',
                }}
              >
                {' '}
                <List sx={{ padding: '0px', gap: '20px' }}>
                  {quoteData.map((detail, index) =>
                    detail.value ? (
                      <ListItem
                        key={index}
                        sx={{ height: '22px', marginTop: '20px' }}
                      >
                        <ListItemText
                          primary={
                            <Typography color="#9CA0AB" variant="subtitle1">
                              {detail.label}
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          {' '}
                          <Typography variant="subtitle1">
                            {detail?.value?.length > 25
                              ? detail.value.substring(0, 25) + ' ...'
                              : detail.value}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ) : null
                  )}
                  <ListItem key={5} sx={{ height: '22px', marginTop: '15px' }}>
                    <ListItemText
                      primary={
                        <Typography color="#9CA0AB" variant="subtitle1">
                          {t('autoRenewel')}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction> </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>

          <Grid item>
            <Typography
              variant="h3"
              sx={{
                marginBottom: '11px',
                marginTop: '30px',
                fontWeight: '400',
              }}
            >
              {t('contactUsText')}
            </Typography>
            <Typography variant="h5">
              <Trans
                i18nKey="contactUsEmail"
                t={t}
                components={{
                  email: (
                    <Link
                      underline="none"
                      href="https://wa.me/+4915904367183"
                      target="_blank"
                      onClick={() =>
                        trackEvent({
                          event: 'Button click',
                          action: 'Done',
                        })
                      }
                    >
                      t{'contactUsEmail'}
                    </Link>
                  ),
                }}
              ></Trans>
            </Typography>
            <PrimaryButton
              loadingLogic={true}
              sx={{ marginBottom: '20px', marginTop: '20px' }}
              onClick={finishProcess}
            >
              {tcommon('done')}
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
      <LongFooter />
    </div>
  );
}

export default PayementCompleted;
