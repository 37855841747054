import { Grid, Typography } from '@mui/material';
//import ZenOwnLogo from '../../assets/insurance/ZenOwn-logo.svg';
import ZenOwnLogo from '../../assets/insurance/ZenOwn.png';
import ZenOwnLogo2 from '../../assets/insurance/ZenOwn@2x.png';
import ZenOwnLogo3 from '../../assets/insurance/ZenOwn@3x.png';
import RVLogo from '../../assets/insurance/RV.png';
import RVLogo2 from '../../assets/insurance/RV@2x.png';
import RVLogo3 from '../../assets/insurance/RV@3x.png';
//import ZurichLogo from '../../assets/insurance/zurich-logo.svg';
//import RVLogo from '../../assets/insurance/r+v-logo.png';
import ElementLogo from '../../assets/insurance/element-logos.png';
import ElementLogo2 from '../../assets/insurance/element-logos@2x.png';
import ElementLogo3 from '../../assets/insurance/element-logos@3x.png';
export const LogoHeader: React.FC = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: '100%',
        minWidth: '100%',
        paddingTop: '18px',
        paddingInline: '9px',
      }}
    >
      <Grid item>
        <img
          src={ZenOwnLogo}
          srcSet={`${ZenOwnLogo} 1x, ${ZenOwnLogo2} 2x, ${ZenOwnLogo3} 3x`}
          style={{
            width: '123px',
            height: '25.76px',
          }}
        />
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          color="#9CA0AB"
          sx={{
            fontSize: '8px',
            fontWeight: '400',
            lineHeight: '18px',
            letterSpacing: '-0.3px',
          }}
        >
          mit freundlicher Unterstützung von
        </Typography>
      </Grid>
      <Grid item>
        {
          <img
            src={RVLogo}
            srcSet={`${RVLogo} 1x, ${RVLogo2} 2x, ${RVLogo3} 3x`}
            style={{
              height: '23px',
              width: '56px',
            }}
            alt="RV Logo"
          />
        }
      </Grid>
    </Grid>
  );
};

export default LogoHeader;
