import { Button, ButtonProps, Typography } from '@mui/material';
export type SecondaryButtonProps = ButtonProps;

export const SecondaryButton: React.FC<SecondaryButtonProps> = (props) => {
  const { sx, children, disabled, ...otherProps } = props;
  return (
    <Button
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 15px',
        gap: '10px',
        position: 'relative',
        height: '29px',

        borderRadius: '10px',
        backgroundColor: disabled ? '#9EC8F9' : '#E0EFFF',
        ...(disabled ? { pointerEvents: 'none' } : {}),
        boxShadow: '0',

        ...props.sx,
        textTransform: 'none',
      }}
      {...otherProps}
      style={{ textTransform: 'none' }}
    >
      <Typography variant="button" color="primary">
        {' '}
        {props.children}
      </Typography>
    </Button>
  );
};

export default SecondaryButton;
