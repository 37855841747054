//@ts-nocheck
import {
  Elements,
  PaymentElement,
  useElements,
  PaymentRequestButtonElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {
  getPriceFormatted,
  getInsurancePriceForQuote,
} from '@zenown-insurance/services/requests';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import SheildPicture from '../../assets/insurance/sheild-logo.png';
import SheildPicture2 from '../../assets/insurance/sheild-logo@2x.png';
import SheildPicture3 from '../../assets/insurance/sheild-logo@3x.png';
import {
  Grid,
  Typography,
  TextField,
  Dialog,
  Divider,
  DialogContent,
  ListItemText,
  ListItem,
} from '@mui/material';

import React, {
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react';
import PrimaryButton from '../primary-button/PrimaryButton';
import InsureProductProcessContext from '../../contexts/InsureProductProcessContext';
import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';

import ErrorCard from '../../assets/insurance/error-card.png';
import ErrorCard2 from '../../assets/insurance/error-card@2x.png';
import ErrorCard3 from '../../assets/insurance/error-card@3x.png';
import SecondaryButton from '../secondary-button/SecondaryButton';
import './stripe.scss';

const StripeForm = () => {
  const [errorDialog, setErrorDialog] = useState<any>(false);
  const [err, setErr] = useState<any>();
  const [errors, setErrors] = useState({
    card: false,
    exp: false,
    cvc: false,
  });
  const [valuesEmpty, setValuesEmpty] = useState({
    card: true,
    exp: true,
    cvc: true,
  });
  const [card, setCard] = useState<any>(undefined);

  const elements = useElements();

  const options = {
    //showIcon: true,
    style: {
      base: {
        iconColor: '#9CA0AB',
        fontFamily: 'Avenir Next',
        fontWeight: '500',
        fontSize: '17px',
        lineHeight: '26px',
        /* identical to box height, or 153% */

        letterSpacing: '-0.3px',
        border: '1px solid #ededed !important',
        borderRadius: '10px',
        '::placeholder': {
          color: '#9CA0AB',
        },
      },
      invalid: {
        iconColor: '#FF2D2D',
        color: '#FF2D2D',
      },
    },
  };
  useEffect(() => {}, [elements]);
  const {
    setIsLoading,
    setErrorMessage,
    receipt,
    clientSecret,
    stripe,
    quote,
    policyId,
    paymentParams,
    applePayPaymentRequest,
    tUserForm: t,
    trackEvent,
    tcommon,
  } = useContext(InsureProductProcessContext);

  useEffect(() => {
    setErrorMessage('');
    trackEvent({
      event: 'Page Visit',
      action: 'checkout page',
    });
  }, []);

  const [ready, setReady] = useState({ cart: false, cvc: false, exp: false });
  useEffect(() => {
    console.log(
      'last isloading',
      Object.values(ready).some((x) => !x),
      ready
    );
    setIsLoading(!ready?.cart);
  }, [ready]);

  useEffect(() => {
    console.log('paymentParams', paymentParams);

    if (!(stripe && applePayPaymentRequest)) {
      console.log('😎 Apple/Google Pay option is not available');
      return;
    }

    console.log('😎 Both Stripe and Apple pay option are ready');

    if (paymentParams == null) {
      console.log('😎 We dont have payment params!');
      return;
    }

    console.log('paymentParams', paymentParams);
    console.log('sending params to stripe ', {
      amount: paymentParams.amount,
      currency: paymentParams.currency,
    });

    console.log('😎 Client secret?? ', clientSecret);

    applePayPaymentRequest.on('paymentmethod', async (ev: any) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      console.log('we sending to confirmpayement those params', {
        clientSecret,
        payment_method: { payment_method: ev.paymentMethod.id },
        handleActions: { handleActions: false },
      });

      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        console.log(
          'cant confirmCardPayment  and this is the error : ',
          confirmError
        );

        setErrorMessage("Oops, couldn't complete the payement");
        ev.complete('fail');
        console.log('event failed ');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          console.log('second attempt of stripe clientSecret: ', clientSecret);
          const { error } = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            console.log('second attempt of stripe failed error: ', error);
            setErrorMessage("Oops, couldn't complete the payement");
            console.log('event failed ');
          } else {
            paymentSuccedded(true);
          }
        } else {
          paymentSuccedded(true);
        }
      }
    });
  }, [stripe, applePayPaymentRequest]);

  function paymentSuccedded(refreshPage = false) {
    localStorage.setItem(
      'payementData',
      JSON.stringify({
        receiptId: receipt?.uuid,
        policyId: policyId,
        premium: quote?.premium,
      })
    );

    if (refreshPage) {
      window.location.reload();
    }
  }

  async function submitStripePayment(event: React.FormEvent<HTMLFormElement>) {
    trackEvent({
      event: 'Button click',
      action: 'Pay button',
    });
    setIsLoading(true);
    event.preventDefault();
    if (!stripe || !elements) {
      console.log('we dont have elements and stripe', stripe, elements);
      return;
    }

    console.log('{{{{{ eleements', elements);

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    });
    console.log('===== HERE ======');
    if (result.error) {
      const error = result.error;
      // Show error to your customer (for example, insufficient funds)
      console.log('===== ERROR RECEIVED ======');
      console.log(result.error.message);

      localStorage.removeItem('payementData');
      setErrorDialog(true);

      if (error.type === 'card_error' || error.type === 'validation_error') {
        setErr(error?.message);
        console.log(error?.message + '');
      } else {
        setErr(t('payementErrors.unexpected'));
        console.log('An unexpected error occurred.');
      }
      setIsLoading(false);
    } else {
      // The payment has been processed!
      console.log('===== ALL GOOD, NO ERROR ======');
      console.log(`Payment intent status ${result.paymentIntent.status}`);

      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        paymentSuccedded(true);
      } else {
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      <Dialog
        open={Boolean(errorDialog)}
        keepMounted
        //style={{ minWidth: '345px' }}
        onClose={() => setErrorDialog(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            marginInline: '15px',
            width: '100%',
            borderRadius: '10px',
          },
        }}
        sx={{
          borderRadius: '10px',
          overflow: 'hidden',
          marginInline: '0px',
          width: '100%',
        }}
      >
        <DialogContent
          sx={{
            //minHeight: '398px',
            //maxHeight: '398px',
            paddingBottom: '24px',
            borderRadius: '10px',
            overflow: 'hidden',
            height: '100%',
            //minWidth: '345px',
            padding: '0px',
            marginBottom: '0px',
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              marginTop: '50px',
              //minWidth: '345px',
              width: '100%',
              paddingInline: '24px',
              marginBottom: '0px',
            }}
          >
            <Grid item sx={{ marginBottom: '20px' }}>
              <img
                src={ErrorCard}
                srcSet={`${ErrorCard} 1x, ${ErrorCard2} 2x, ${ErrorCard3} 3x`}
                style={{
                  width: '110px',
                  height: '96px',
                }}
                alt="Error Card"
              />
            </Grid>
            <Grid item sx={{ marginBottom: '15px' }}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: '600',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                {t('stripeError')}
              </Typography>
            </Grid>
            <Grid item sx={{ marginBottom: '25px' }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: '500',
                  color: '#9CA0AB',
                  fontSize: '12px',
                  fontHeight: '19px',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                {err}
              </Typography>
            </Grid>

            <Grid item sx={{ marginBottom: '20px', width: '100%' }}>
              <PrimaryButton
                sx={{ fontStyle: 'Semibold S', width: '100%' }}
                onClick={() => setErrorDialog(false)}
              >
                {tcommon('close')}
              </PrimaryButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <form id="payment-form" onSubmit={submitStripePayment}>
        <div
          id="card"
          style={{
            marginTop: '20px',
            position: 'relative',
          }}
        >
          {card && (
            <img
              style={{
                width: '30px',
                position: 'absolute',
                right: '15px',
                top: '22px',
                zIndex: '99',
              }}
              src={window.location.origin + `/assets/cards/${card}.svg`}
            />
          )}

          <TextField
            label="Card Number"
            variant="filled"
            required
            fullWidth
            error={errors?.card}
            InputLabelProps={{
              sx: {
                marginTop: '6px',
                root: {
                  marginTop: '6px',
                },
                '&.MuiInputLabel-shrink': {
                  marginTop: '14px',
                },
              },
              required: false,
              error: errors?.card,
              shrink: !valuesEmpty?.card ? true : undefined,
            }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                sx: {
                  '&.MuiFilledInput-input': {
                    paddingTop: '30px !important ',
                    paddingBottom: '6px !important',
                  },
                },
                id: 'card number',
                trackEvent,
                ready: ready?.cart,
                setReady: (e: any) => setReady({ ...ready, cart: true }),
                setError: (b: boolean) => setErrors({ ...errors, card: b }),
                valuesEmpty: valuesEmpty?.card,
                setCard,
                setValuesEmpty: (b: boolean) =>
                  setValuesEmpty({ ...valuesEmpty, card: b }),
                component: CardNumberElement,
              },
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
            gap: '14px',
          }}
        >
          <TextField
            label="MM/YY"
            variant="filled"
            sx={{
              minWidth: 'calc(50% -7px)',
              width: 'calc(50% -7px)',
              maxWidth: 'calc(50% -7px)',
            }}
            required
            fullWidth
            error={errors?.exp}
            InputLabelProps={{
              sx: {
                marginTop: '6px',
                root: {
                  marginTop: '6px',
                },
                '&.MuiInputLabel-shrink': {
                  marginTop: '14px',
                },
              },
              error: errors?.exp,
              required: false,
              shrink: !valuesEmpty?.exp ? true : undefined,
            }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                sx: {
                  '&.MuiFilledInput-input': {
                    paddingTop: '30px !important ',
                    paddingBottom: '6px !important',
                  },
                },
                id: 'MM/YY',
                trackEvent,
                ready: ready?.exp,
                //setReady: (e: any) => setReady({ ...ready, exp: true }),
                setError: (b: boolean) => setErrors({ ...errors, exp: b }),
                valuesEmpty: false,
                setValuesEmpty: (b: boolean) =>
                  setValuesEmpty({ ...valuesEmpty, exp: b }),
                component: CardExpiryElement,
              },
            }}
          />{' '}
          <TextField
            sx={{
              minWidth: 'calc(50% -7px)',
              width: 'calc(50% -7px)',
              maxWidth: 'calc(50% -7px)',
            }}
            error={errors?.cvc}
            label="CVC"
            variant="filled"
            required
            fullWidth
            InputLabelProps={{
              sx: {
                marginTop: '6px',
                root: {
                  marginTop: '6px',
                },
                '&.MuiInputLabel-shrink': {
                  marginTop: '14px',
                },
              },
              error: errors?.cvc,
              required: false,
              shrink: !valuesEmpty?.cvc ? true : undefined,
            }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                sx: {
                  '&.MuiFilledInput-input': {
                    paddingTop: '30px !important ',
                    paddingBottom: '6px !important',
                  },
                },
                id: 'CVC',
                trackEvent,
                ready: ready?.cvc,
                setError: (b: boolean) => setErrors({ ...errors, cvc: b }),
                // setReady: (e: any) => setReady({ ...ready, cvc: true }),
                valuesEmpty: false,
                setValuesEmpty: (b: boolean) =>
                  setValuesEmpty({ ...valuesEmpty, cvc: b }),
                component: CardCvcElement,
              },
            }}
          />
        </div>

        <PrimaryButton
          loadingLogic={true}
          type="submit"
          disabled={Object.values(errors).some((x) => x === true)}
          sx={{
            marginTop: '32px !important',
          }}
        >
          <Typography variant="button" color="#FFFFFF"></Typography>
          {t('payNow')}
        </PrimaryButton>
        {
          //Only show Apple Pay button if applePayPaymentRequest is initialized
          applePayPaymentRequest && (
            <>
              <Divider sx={{ marginBottom: '30px', marginTop: '30px' }}>
                <Typography
                  sx={{
                    fontWeight: '510',
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#383A49',
                  }}
                >
                  OR
                </Typography>
              </Divider>
              <PaymentRequestButtonElement
                options={{
                  paymentRequest: applePayPaymentRequest,
                  style: {
                    paymentRequestButton: {
                      theme: 'dark',
                      height: '50px',
                    },
                  },
                }}
              />
            </>
          )
        }
      </form>
    </>
  );
};

const StripeInput = ({
  component: Component,
  ready,
  setReady,
  setError,
  valuesEmpty,
  setValuesEmpty,
  inputRef,
  setCard,
  showIcon,
  trackEvent,
  id,
  ...props
}) => {
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));
  const { clientSecret } = useContext(InsureProductProcessContext);
  const options = useMemo(() => {
    return {
      clientSecret,
      //showIcon: valuesEmpty,
      placeholder: '',
      style: {
        base: {
          iconColor: '#9CA0AB',
          fontFamily: 'Avenir Next',
          fontWeight: '500',
          fontSize: '17px',

          /* identical to box height, or 153% */

          letterSpacing: '-0.3px',
          '::placeholder': {
            color: '#9CA0AB',
          },
        },
        invalid: {
          iconColor: '#FF2D2D',
          color: '#FF2D2D',
        },
      },
    };

    if (showIcon) {
      o.showIcon = true;
      // o.iconStyle = 'solid';
    }

    return o;
  }, [showIcon]);
  return (
    <Component
      onReady={(element) => {
        elementRef.current = element;
        if (setReady) setReady(true);
      }}
      {...props}
      placeholder={''}
      options={options}
      onChange={(event: any) => {
        trackEvent({
          event: 'Input',
          action: id,
        });
        setError(Boolean(event?.error));
        setValuesEmpty(event?.empty ? true : false);
        if (setCard && event?.brand && event?.brand !== 'unknown') {
          setCard(event?.brand + '');
        } else {
          setCard(undefined);
        }
      }}
    />
  );
};
export const StripePayForm = () => {
  const {
    stripe,
    setErrorMessage,
    errorMessage,
    clientSecret: formState,
    policyId,
    setCurrentProcessStep,
    quote,
    tUserForm: t,
  } = useContext(InsureProductProcessContext);
  useEffect(() => {
    setErrorMessage('');
  }, []);
  const options = useMemo(() => {
    const clientSecret = formState;
    return {
      clientSecret,
    };
  }, [formState]);

  return (
    <div style={{ position: 'relative' }} className="stripe-form">
      <>
        {' '}
        <ListItem alignItems="flex-start" sx={{ paddingInline: '0px' }}>
          <>
            <img
              src={SheildPicture}
              srcSet={`${SheildPicture} 1x, ${SheildPicture2} 2x, ${SheildPicture3} 3x`}
              style={{
                height: '35px',
                width: '35px',
                marginRight: '15px',
                verticalAlign: 'middle',
                marginTop: '6px',
              }}
            />
            <ListItemText
              primary={
                <Typography variant="h3">
                  {getInsurancePriceForQuote(quote)}
                </Typography>
              }
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline', fontWeight: '500' }}
                    component="span"
                    variant="subtitle1"
                  >
                    {t('coversFor')} 1 {t('year')}
                  </Typography>
                </>
              }
            />
            {/* <SecondaryButton
              sx={{
                marginTop: '6px',
                margin: 'auto',
                width: '85px',
                backgroundColor: '#FFFFFF',
                height: '34px',
                padding: '8px 10px',
                verticalAlign: 'middle',
                fontSize: '40px',
              }}
            >
              <Typography
                variant="button"
                color="primary"
                sx={{
                  whiteSpace: 'nowrap',
                  fontStyle: 'Semibold XL !important',
                  fontFamily: 'Avenir Next',
                  fontWeight: '600',
                  fontSize: '12px',
                  lineHeight: '18px',
                  letterSpacing: '-0.26px',
                }}
                onClick={() => {
                  setCurrentProcessStep('quoteProposition');
                }}
                style={{
                  fontSize: '12px',
                  whiteSpace: 'nowrap',
                  fontStyle: 'Semibold XL !important',
                }}
              >
                {t('viewPlan')}
              </Typography>
            </SecondaryButton> */}
          </>
        </ListItem>
        <div
          style={{
            position: 'relative',
            marginTop: '18px',
            marginBottom: '30px',
          }}
        >
          <Divider
            sx={{
              width: '100%',
              maxWidth: '345px',
              margin: 'auto',
            }}
          />
        </div>
      </>
      <Elements
        stripe={stripe}
        options={{
          locale: localStorage.getItem('i18nextLng'),
          ...{
            ...options,
            fonts: [
              {
                family: 'Avenir Next',
                src: 'url(https://zenown-cdn.s3.eu-central-1.amazonaws.com/fonts/AvenirNextLTPro-Medium.otf)',
                unicodeRange:
                  'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215',
              },
            ],
          },
        }}
      >
        <StripeForm />
      </Elements>
    </div>
  );
};
export default StripePayForm;
