import { Box, Container, Paper, useMediaQuery } from '@mui/material';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export type InsuranceLayoutProps = {};

export const InsuranceLayout: React.FC<InsuranceLayoutProps> = ({
  children,
}) => {
  //TODO: use theme lyout and use the theme variable for switchign themes in teh future
  const matches = useMediaQuery('(min-width:500px)');
  return (
    <>
      <Paper
        sx={{
          minWidth: '100%',
          minHeight: '100vh',
          backgroundColor: matches ? '#E4E4E4' : 'white',
          borderRadius: '0px',
          maxWidth: '430px !important',
          width: 'auto',
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          id="layout-box"

          //minHeight="100vh"
        >
          <Container
            id="layout-container"
            sx={{
              paddingLeft: '15px!important',
              paddingRight: '15px!important',
              position: 'sticky',
              maxWidth: '430px !important',
              minWidth: '375px',
              width: '100%',
              //maxHeight: '708px',
              //height: '708px',
              minHeight: '100vh',
              height: 'auto',
              bgcolor: '#FFFFFF',
              margin: 0,

              // marginBottom: '21px',
              //marginTop: '29.65px',
            }}
          >
            {children}
          </Container>{' '}
        </Box>
      </Paper>
    </>
  );
};

export default InsuranceLayout;
