import { ApiServices } from './api-services';
import { environment } from "@env-api/environment";
import { IDataResponse } from '@zenown-insurance/api-interfaces';

export interface IAwsS3 {
  ETag: string,
  VersionId: string,
  Location: string,
  key: string,
  Key: string,
  Bucket: string,
  size?:[number,number]
}

export class UploadService {
  private _apiServices: ApiServices = new ApiServices(environment);

  public set apiServices(apiService: ApiServices){
    this._apiServices = apiService;
  }

  public get apiServices(){
    return this._apiServices ;
  }
  public uploadDetectText = async (file: FormData): Promise<IDataResponse<any>> => {
    return this._apiServices.postRequest('upload_detect_text', file);
  }
  public uploadAnalyzeText = async (file: FormData): Promise<IDataResponse<any>> => {
    return this._apiServices.postRequest('upload_analyzing_document', file);
  }
  public uploadFile = async (file: FormData): Promise<IDataResponse<IAwsS3>> => {
    return this._apiServices.postRequest('file_upload', file);
  }

  public getFile = async (key: string, size: string): Promise<IDataResponse<any>> => {
    return this._apiServices.postRequest('get_file', {key, size});
  }

  public uploadAndAnaliseFile = async (file: FormData): Promise<IDataResponse<IAwsS3>> => {
    return this._apiServices.postRequest('upload_and_analyzing_document', file);
  }

  public readQRCode = async (file: FormData): Promise<IDataResponse<any>> => {
    return this._apiServices.postRequest('data_matrix', file);
  }

  public getInvoiceData = async (uuid: string): Promise<IDataResponse<any>> => {
    return this._apiServices.getRequest(`get_document/${uuid}`,  {}, {},{authenticated: false});
  }



}
