import {
  Button,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  circularProgressClasses,
  Box,
} from '@mui/material';
import moment from 'moment';
import CheckedIcon from '../../assets/insurance/checked-icon.svg';
import TrashIcon from '../../assets/insurance/trash-icon.svg';
import CameraIcon from '../../assets/insurance/camera-icon.svg';
import { useContext, useEffect, useState } from 'react';
import ImageUploading from './Uploader'; //'react-images-uploading';
import { ImageListType } from './Uploader'; //'react-images-uploading/dist/typings';
import InsureProductProcessContext from '../../contexts/InsureProductProcessContext';

// Inspired by the former Facebook spinners.
function CProgress(props: any) {
  return (
    <Box sx={{ position: 'relative', maxHeight: '68px' }}>
      <CircularProgress
        sx={{
          color: '#F0F7FF',
        }}
        size={67}
        thickness={4.5}
        {...props}
        value={100}
        variant="determinate"
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === 'light' ? '#0179FF' : '#308fe8',
          animationDuration: '550ms',
          position: 'absolute',
          margin: 'auto',
          marginLeft: '-67px !important',

          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={67}
        thickness={4.5}
        {...props}
      ></CircularProgress>
      {props.value >= 0 && (
        <Typography
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '12px',
            fontWeight: '600',
            lineHeight: '18px',
          }}
        >{`${Math.round(props.value)}%`}</Typography>
      )}
    </Box>
  );
}
export interface UploadSectionProsp {
  onChange: (evt: any) => Promise<void>;
  files?: any;
  text?: string;
  trackEvent?: any;
  id?: string;
  key?: string;
  setUploadingFile?: any;
}

export const UploadSection = (props: UploadSectionProsp) => {
  const { progress, tUpload: t } = useContext(InsureProductProcessContext);
  const [loadingFile, setLoadingFile] = useState(false);

  const { onChange, files, key } = props;
  const [images, setImages] = useState(files && files.length > 0 ? files : []);
  const onChangeEvt = async (
    imagesList: ImageListType,
    addUpdatedIndex?: Array<number>
  ) => {
    setImages(imagesList);
    await onChange(imagesList);
    setLoadingFile(false);
  };

  if (loadingFile)
    return (
      <CProgress
        variant="indeterminate"
        //size={40}
        // thickness={4}
      />
    );

  return (
    <>
      {}
      {progress && progress > 0 ? (
        <CProgress
          variant="determinate"
          //size={40}
          // thickness={4}
          value={progress}
        />
      ) : (
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="start"
          justifyContent="center"
          textAlign={'center'}
          style={{
            marginInline: '0px',
            padding: '0px 0px',
            width: '100%',
            height: '68px',
          }}
        >
          <ImageUploading
            value={images}
            onChange={onChangeEvt}
            maxNumber={1}
            resolutionType="absolute"
            //@ts-ignore
            setLoadingFile={(v: boolean) => {
              setLoadingFile(v);
              if (props.setUploadingFile) props.setUploadingFile(v);
            }}
            acceptType={['heic', 'heif', 'jpeg', 'jpg', 'png']}
            onError={(errors) => {
              console.log('errors image upload', errors);
            }}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <Grid item xs={12} textAlign={'center'}>
                {imageList.length === 0 && (
                  <Button
                    id={props.id}
                    key={key + ''}
                    fullWidth={true}
                    style={{
                      backgroundColor: '#F0F7FF',
                      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%230179FFFF' stroke-width='2' stroke-dasharray='3%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                      borderRadius: 10,
                      textTransform: 'none',
                      height: 68,
                      color: '#0179FF',
                      fontWeight: '500',
                      fontStyle: 'Medium S',
                    }}
                    onClick={(e) => {
                      if (props.trackEvent)
                        props.trackEvent({
                          event: 'Button click',
                          action: 'Upload photo',
                        });
                      onImageUpload();
                    }}
                    {...dragProps}
                  >
                    <img
                      key={key + ''}
                      src={CameraIcon}
                      style={{
                        paddingRight: 10,
                        height: '24px',
                        width: '24px',
                      }}
                      alt="CameraIcon"
                    />{' '}
                    {props?.text || t('uploadText')}
                  </Button>
                )}

                {imageList.length > 0 && (
                  <>
                    {imageList.map((image, index) => {
                      const extention =
                        image?.file?.name?.split('.').pop() || '';

                      const fileName = image.file
                        ? image.file.name
                            .replace(
                              extention.length ? '.' + extention : '',
                              ''
                            )
                            .substring(0, 11)
                        : '';
                      const dateModified = image.file
                        ? moment(image.file.lastModified).format(
                            'DD/MM/YYYY HH:mm'
                          )
                        : '';
                      return (
                        <Grid
                          key={index}
                          container
                          direction={'row'}
                          spacing={0}
                          textAlign={'left'}
                          alignItems="stretch"
                          justifyContent="space-between"
                        >
                          <Grid item xs={1}>
                            <img
                              src={image['data_url']}
                              key={key + ''}
                              alt=""
                              style={{
                                width: 48,
                                height: 51,
                                border: '1px solid #F0F0F0',
                                objectFit: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: '50% 50%',
                                borderRadius: 10,
                              }}
                            />
                          </Grid>
                          <Grid item xs={8} style={{ marginLeft: '10px' }}>
                            <Typography
                              variant="body1"
                              sx={{
                                paddingRight: 6,
                                textOverflow: 'ellipsis',
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              {fileName.length > 12
                                ? fileName.slice(0, 12)
                                : fileName}
                              <Typography
                                variant="body1"
                                sx={{
                                  color: '#9CA0AB',
                                  maxHeight: '26px',
                                  position: 'relative',
                                }}
                              >
                                .{extention}
                                <img
                                  src={CheckedIcon}
                                  style={{
                                    position: 'absolute',
                                    float: 'right',
                                    marginTop: '6px',
                                    marginLeft: '3px',
                                    verticalAlign: 'middle',
                                  }}
                                  alt="CheckedIcon"
                                />
                              </Typography>
                            </Typography>

                            <Typography
                              variant="caption"
                              sx={{ color: '#9CA0AB' }}
                            >
                              {dateModified}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} sx={{ paddingTop: '8px' }}>
                            <IconButton
                              sx={{ padding: '0px' }}
                              onClick={() => {
                                if (props.trackEvent)
                                  props.trackEvent({
                                    event: 'Button click',
                                    action: 'Remove photo',
                                  });
                                onImageRemove(index);
                                onChange(undefined);
                              }}
                            >
                              <img src={TrashIcon} alt="TrashIcon" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                )}
              </Grid>
            )}
          </ImageUploading>
        </Grid>
      )}
    </>
  );
};
