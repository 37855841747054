import { ThemeProvider } from '@mui/material';

import { InsuranceLayout } from '../components/zenown-insurance';
import { InsureProductProcessContextProvider } from '../contexts/InsureProductProcessContext';
import InsureProductPage from '../pages/zenown-insurance/InsureProductPage/InsureProductPage';
import { createMuiTheme } from './zenown-insurance/muiThemeCreation';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SerialNumberHow from '../pages/zenown-insurance/InsureProductPage/components/SerialNumberHow';

export interface ZenownInsuranceContainerProps {
  path?: 'serial-number';
}
const theme = createMuiTheme();

export function ZenownInsuranceContainer(props: ZenownInsuranceContainerProps) {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          {' '}
          <InsuranceLayout>
            {props.path ? (
              <SerialNumberHow />
            ) : (
              <InsureProductProcessContextProvider>
                <InsureProductPage />
              </InsureProductProcessContextProvider>
            )}
          </InsuranceLayout>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
}

export default ZenownInsuranceContainer;
