import { Dictionary, MappedError } from 'express-validator/shared-typings';
import { v1 as uuidv1 } from 'uuid';

/*
  Response message formatter
  This file defines the messages structure to send back
 */

export function successFormatter(data?: any) {
  return {
    status: 'ok',
    data: data ? data : {},
  };
}

export function validationFormatter(
  validation: Dictionary<MappedError> | MappedError[]
) {
  return {
    status: 'validation_error',
    error_id: uuidv1(),
    error_message: validation,
  };
}

export function errorFormatter(error: Error) {
  return {
    status: 'error',
    error_id: uuidv1(),
    error_message: error.message,
  };
}

export interface Message {
  message: string;
}

export type StatusCode = 'ok' | 'error';

interface IApiResponse {
  status?: StatusCode;
}

export interface IDataResponse<T> extends IApiResponse {
  data: T;
  statusCode?: number;
  errorCode?: number;
  error?: string;
  rejectionCode?: number;
  rejection?: string;
}

export interface IDataListResponse<T> extends IApiResponse {
  data: T[];
  count: number;
  first_result?: number;
  max_results?: number;
  total?: number;
}

export interface IApiOptions {
  adminApi?: any;
  withCredentials?: boolean;
  authenticated?: boolean;
  sessionToken?: string;
  backOffice?: boolean;
}

export function paramsToQueryString(paramsArg: { [key: string]: any } = {}) {
  if (!paramsArg) {
    return '';
  }
  const paramsToArray: string[] = Object.keys(paramsArg);
  const str: string = paramsToArray
    .filter((key) => paramsArg[key] !== undefined)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(paramsArg[key])}`
    )
    .join('&');
  return str.length ? `?${str}` : '';
}

export function toUnicode(word: string) {
  let out = '';
  for (let i = 0; i < word.length; i++) {
    const ch = word.charAt(i);
    const chn = ch.charCodeAt(0);
    if (chn <= 127) out += ch;
    else {
      let hex = chn.toString(16);
      if (hex.length < 4) hex = '000'.substring(hex.length - 1) + hex;
      out += '\\u' + hex;
    }
  }
  return out;
}

export function encode_utf8(s: string) {
  return unescape(encodeURIComponent(s));
}

export function substr_utf8_bytes(
  str: string,
  startInBytes: number,
  lengthInBytes: number
) {
  /* this function scans a multibyte string and returns a substring.
    * arguments are start position and length, both defined in bytes.
    *
    * this is tricky, because javascript only allows character level
    * and not byte level access on strings. Also, all strings are stored
    * in utf-16 internally - so we need to convert characters to utf-8
    * to detect their length in utf-8 encoding.
    *
    * the startInBytes and lengthInBytes parameters are based on byte
    * positions in a utf-8 encoded string.
    * in utf-8, for example:
    *       "a" is 1 byte,
            "ü" is 2 byte,
       and  "你" is 3 byte.
    *
    * NOTE:
    * according to ECMAScript 262 all strings are stored as a sequence
    * of 16-bit characters. so we need a encode_utf8() function to safely
    * detect the length our character would have in a utf8 representation.
    *
    * http://www.ecma-international.org/publications/files/ecma-st/ECMA-262.pdf
    * see "4.3.16 String Value":
    * > Although each value usually represents a single 16-bit unit of
    * > UTF-16 text, the language does not place any restrictions or
    * > requirements on the values except that they be 16-bit unsigned
    * > integers.
    */

  let resultStr = '';
  let startInChars = 0;

  // scan string forward to find index of first character
  // (convert start position in byte to start position in characters)

  for (let bytePos = 0; bytePos < startInBytes; startInChars++) {
    // get numeric code of character (is >128 for multibyte character)
    // and increase "bytePos" for each byte of the character sequence

    const ch = str.charCodeAt(startInChars);
    bytePos += ch < 128 ? 1 : encode_utf8(str[startInChars]).length;
  }

  // now that we have the position of the starting character,
  // we can built the resulting substring

  // as we don't know the end position in chars yet, we start with a mix of
  // chars and bytes. we decrease "end" by the byte count of each selected
  // character to end up in the right position
  let end = startInChars + lengthInBytes - 1;

  for (let n = startInChars; startInChars <= end; n++) {
    // get numeric code of character (is >128 for multibyte character)
    // and decrease "end" for each byte of the character sequence
    const ch = str.charCodeAt(n);
    end -= ch < 128 ? 1 : encode_utf8(str[n]).length;

    resultStr += str[n];
  }

  return resultStr;
}
