import {
  Button,
  Checkbox,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  DialogContent,
  Dialog,
  TextField,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { Footer } from '../../../components/footer/Footer';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { LanguageSection } from '../../../components/zenown-insurance/LanguageSection';
import { UploadSection } from '../../../components/zenown-insurance/UploadSection';
import { UploadPageHeader } from '../../../components/zenown-insurance';
import { ProcessingInsuranceRequest } from './components/ProcessingInsuranceRequest';
import SerialSuccess from '../../../assets/insurance/serial-success.png';
import SerialSuccess2 from '../../../assets/insurance/serial-success@2x.png';
import SerialSuccess3 from '../../../assets/insurance/serial-success@3x.png';
import QuoteProposition from './components/QuoteProposition';
import UserPayementForm from './components/UserPayementForm';
import PayementCompleted from './components/PayementCompleted';
import InsureProductProcessContext from '../../../contexts/InsureProductProcessContext';
import ErrorMessage from '../../../components/error-message/ErrorMessage';
import EmbeddedLogo from '../../../assets/insurance/EmbeddedLogo.png';
import EmbeddedLogo2 from '../../../assets/insurance/EmbeddedLogo@2x.png';
import EmbeddedLogo3 from '../../../assets/insurance/EmbeddedLogo@3x.png';
import Headphones from '../../../assets/insurance/Headphones.png';
import Headphones2 from '../../../assets/insurance/Headphones@2x.png';
import Headphones3 from '../../../assets/insurance/Headphones@3x.png';
import ProcessingSerialRequest from './components/ProcessingSerialRequest';
import SurveryScreen from './components/SurveryScreen';
import BlueUnCheckBoxIcon from '../../../assets/insurance/blueUnCheckBoxIcon.svg';
import BlueCheckBoxIcon from '../../../assets/insurance/blueCheckBoxIcon.svg';
import { Trans } from 'react-i18next';
import ReturnButton from '../../../components/return-button/ReturnButton';
import SupportSheet from '../../../components/zenown-insurance/SupportSheet';
import { EmbeddedInsuranceService } from '@zenown-insurance/services/requests';
import CookiesSheet from '../../../components/zenown-insurance/CookiesSheet';
import { last } from 'lodash';
import CalculatorScreen from './components/CalculatorScreen';

//return if outside work hours
const lastSundayOfTheMonth = (month = 3) => {
  const d = new Date();
  const firstDayOfNextMonth = new Date(
    Date.UTC(d.getUTCFullYear(), month, 1, 10, 10)
  );

  let day = firstDayOfNextMonth.getUTCDay();

  if (day == 0) {
    day = 7;
  }

  const lastSunday = new Date(
    firstDayOfNextMonth.getTime() - day * 86400 * 1000
  );

  return lastSunday.getUTCDate();
};

export const isOutsideWorkHours = (start = 8, end = 20) => {
  // if (process.env['NX_INSURANCE_API_URL'] != 'https://bike-api.zenprotect.io') {
  //   return false;
  // }
  //if (process.env['NODE_ENV'] === 'development') return false;
  const now = new Date();
  if (now.getDay() === 0) {
    return true;
  }

  const startInMinutes = start * 60;
  const endInMinutes = end * 60;

  const utcH = now.getUTCHours();
  const utcM = now.getUTCMinutes();

  let isDst = false;

  const realMonth = now.getUTCMonth() + 1;
  const dayOfTheMonth = now.getUTCDate();

  if (realMonth >= 4 && realMonth < 10) {
    isDst = true;
  }

  if (realMonth == 3) {
    //in March and October dst changes, it changes on the last sunday of the month
    const lastSundayInMarch = lastSundayOfTheMonth(3);
    if (dayOfTheMonth > lastSundayInMarch) {
      isDst = true;
    }
  }

  if (realMonth == 10) {
    const lastSundayInOctober = lastSundayOfTheMonth(10);
    if (dayOfTheMonth < lastSundayInOctober) {
      isDst = true;
    }
  }

  const cetInMinutes = utcH * 60 + utcM + (isDst ? 2 : 1) * 60;

  // console.log(`CET time is ${Math.floor(cetInMinutes / 60)}:${cetInMinutes - Math.floor(cetInMinutes / 60) * 60}`)

  if (cetInMinutes >= startInMinutes && cetInMinutes < endInMinutes) {
    console.log('----We are in WORKING HOURS----');
    return false;
  } else {
    console.log('----NOPE We are NOT in WORKING HOURS NOPE----');
    return true;
  }
};

export function InsureProductPage() {
  const [showSheet, setShowSheet] = useState(false);
  const [width, setWidth] = useState(375);
  const [legallyAccepted, setLegallyAccepted] = useState(
    localStorage.getItem('legallyAccepted') === 'true'
  );
  const [lastFailed, setLastFailed] = useState(
    Number(
      localStorage.getItem('lastFailed')
        ? localStorage.getItem('lastFailed')
        : 0
    )
  );

  const [phoneNumber, setPhoneNumber] = useState('+49 ');
  const [submittedPhoneNumber, setSubmittedPhoneNumber] = useState(false);
  const afterDialogWorkHours = async () => {
    /*setSubmittedPhoneNumber(false);
    trackEvent({
      event: 'Button click',
      action: 'Get Your Quote',
    });
    setIsLoading(true);
    await uploadFile(file);
    setIsLoading(false);*/
  };

  const {
    calculatorModalOpen,
    outWorkHours,
    setOutWorkHours,
    receiptRejectedCount,
    setReceiptRejectedCount,
    setFile,
    setIsLoading,
    currentProcessStep,
    setCurrentProcessStep,
    showHowItWorks,
    setShowPolicy,
    setShowTerms,
    submitFile,
    file,
    progress,
    receipt,
    uploadFile,
    errorMessage,
    tUpload: t,
    setErrorMessage,
    trackEvent,
    tcommon,
  } = useContext(InsureProductProcessContext);

  useEffect(() => {
    const element = document.getElementById('layout-container');
    if (element) {
      if (currentProcessStep === 'upload' && calculatorModalOpen) {
        element.style.background =
          'linear-gradient(to bottom, #F8FAFC, #FFFFFF)';
      } else {
        element.style.background = '#FFFFFF';
      }
    }
  }, [currentProcessStep]);
  useEffect(() => {
    const element = document.getElementById('layout-box');
    if (element) {
      const positionInfo = element?.getBoundingClientRect();
      setWidth(positionInfo.width);
    }
    document.title = t('title');
    console.log(`Is outside of working hours ${isOutsideWorkHours()}`);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (receiptRejectedCount >= 2) {
      setShowSheet(true);
      localStorage.setItem('lastFailed', Date.now() + '');
      setLastFailed(Date.now());
    }
  }, [receiptRejectedCount]);
  useEffect(() => {
    if (currentProcessStep === 'upload')
      trackEvent({
        event: 'Page Visit',
        action: 'landing_page',
      });
    else if (currentProcessStep === 'quoteProposition')
      trackEvent({
        event: 'Page Visit',
        action: 'Quote proposition',
      });
  }, [currentProcessStep]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentProcessStep]);
  if (currentProcessStep === 'survey') return <SurveryScreen />;
  if (currentProcessStep === 'processing') {
    return (
      <>
        <ProcessingInsuranceRequest />
        <Dialog
          PaperProps={{
            sx: {
              marginInline: '15px',
              width: '100%',
              borderRadius: '10px',
            },
          }}
          sx={{
            borderRadius: '10px',
            overflow: 'hidden',
            marginInline: '0px',
            width: '100%',
          }}
          open={outWorkHours}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => {
            setOutWorkHours(false);
            afterDialogWorkHours();
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <ReturnButton
            style={{
              position: 'absolute',
              right: 0,
              marginRight: '15px',
              marginTop: '15px',
            }}
            close={true}
            onClick={() => {
              setOutWorkHours(false);
              afterDialogWorkHours();
            }}
          />
          <DialogContent
            sx={{
              paddingBottom: '24px',
              borderRadius: '10px',
            }}
          >
            {submittedPhoneNumber ? (
              <>
                {' '}
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    marginTop: '30px',
                    //minWidth: '345px',
                    width: '100%',
                    //paddingInline: '24px',
                    marginBottom: '0px',
                  }}
                >
                  <Grid item sx={{ marginBottom: '20px' }}>
                    <img
                      src={SerialSuccess}
                      srcSet={`${SerialSuccess} 1x, ${SerialSuccess2} 2x, ${SerialSuccess3} 3x`}
                      style={{
                        width: '72.45px',
                        height: '72.45px',
                      }}
                      alt=""
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h3"
                      sx={{
                        marginBottom: '15px',
                        fontWeight: '600',
                        fontSize: '17px',
                        lineHeight: '23.22px',
                        letterSpacing: '-0.3px',

                        textAlign: 'center',
                      }}
                    >
                      {t('phoneSubmittedtitle')}
                    </Typography>

                    <Typography
                      color="#9CA0AB"
                      sx={{
                        fontWeight: '500',
                        fontSize: '12px',
                        lineHeight: '18px',
                        letterSpacing: '-0.3px',
                        textAlign: 'center',
                      }}
                    >
                      {t('phoneSubmittedSubtitle')}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ marginTop: '20px', width: '100%' }}>
                    <PrimaryButton
                      sx={{ fontStyle: 'Semibold S' }}
                      onClick={() => {
                        setOutWorkHours(false);
                        afterDialogWorkHours();
                      }}
                    >
                      {t('done')}
                    </PrimaryButton>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {' '}
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    marginTop: '30px',
                    //marginBottom: '20px',
                    width: '100%',
                  }}
                >
                  <Grid item>
                    <img
                      src={Headphones}
                      srcSet={`${Headphones} 1x, ${Headphones2} 2x, ${Headphones3} 3x`}
                      style={{
                        width: '110px',
                        height: '96px',
                      }}
                      alt="Phone Icon"
                    />
                  </Grid>
                  <Grid item sx={{ paddingBottom: '15px', marginTop: '20px' }}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: '600',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                      }}
                    >
                      {t('outsideWorkhourstitle')}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ marginBottom: '15px' }}>
                    <Typography
                      variant="caption"
                      color="#9CA0AB"
                      sx={{
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                      }}
                    >
                      {t('outsideWorkhoursSubtitle')}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ marginBottom: '15px', width: '100%' }}>
                    <TextField
                      sx={{ minWidth: '250px', width: '100%' }}
                      defaultValue={phoneNumber}
                      label={t('phoneNumber')}
                      fullWidth
                      value={phoneNumber}
                      onChange={(event) => {
                        setPhoneNumber(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ marginBottom: '20px', width: '100%' }}>
                    <PrimaryButton
                      sx={{ fontStyle: 'Semibold S' }}
                      loadingLogic={true}
                      disabled={phoneNumber.length < 6}
                      onClick={async () => {
                        try {
                          setIsLoading(true);
                          const embeddedInsuranceService =
                            new EmbeddedInsuranceService();
                          await embeddedInsuranceService.notifyPhoneNumber(
                            phoneNumber
                          );
                          setSubmittedPhoneNumber(true);
                        } catch (err) {
                        } finally {
                          setIsLoading(false);
                        }
                      }}
                    >
                      {t('submit')}
                    </PrimaryButton>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      sx={{
                        marginBottom: '0px',
                        color: '#383A49',
                        padding: '0',
                        fontStyle: 'Semibold S',
                      }}
                      onClick={() => {
                        setOutWorkHours(false);
                        afterDialogWorkHours();
                      }}
                    >
                      {tcommon('cancel')}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  }
  if (currentProcessStep === 'quoteProposition') {
    return <QuoteProposition />;
  }
  if (
    currentProcessStep === 'payementForm' ||
    currentProcessStep === 'checkout' ||
    currentProcessStep === 'serial' ||
    currentProcessStep === 'legalForm' ||
    currentProcessStep === 'processingSerial'
  ) {
    return <UserPayementForm />;
  }

  if (currentProcessStep === 'payementCompleted') {
    return <PayementCompleted />;
  }
  /*if (currentProcessStep === 'processingSerial') {
    return (
      <ProcessingSerialRequest
        onCancel={() => setCurrentProcessStep('serial')}
        onContinue={() => {
          setCurrentProcessStep('legalForm');
        }}
      />
    );
  }*/
  if (currentProcessStep === 'upload') {
    if (calculatorModalOpen) return <CalculatorScreen />;
    else
      return (
        <>
          {}
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            textAlign="center"
          >
            <Grid item textAlign={'center'} sx={{ width: '100%' }}>
              <UploadPageHeader />
            </Grid>

            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="stretch"
              textAlign={'center'}
              sx={{ marginTop: '30px', padding: '0', minHeight: '28vh' }}
            >
              <Grid item sx={{ minHeight: '121px', minWidth: '340px' }}>
                <img
                  id="bikeImage"
                  src={EmbeddedLogo}
                  srcSet={`${EmbeddedLogo} 1x, ${EmbeddedLogo2} 2x, ${EmbeddedLogo3} 3x`}
                  style={{
                    //position: 'absolute',
                    width: 'calc(100% + 30px)',
                    marginLeft: '-15px',
                    marginBottom: '25px',
                  }}
                />

                <Typography
                  variant="h1"
                  sx={{
                    marginInline: '24px',

                    minHeight: '67px',
                  }}
                >
                  {t('title')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#9CA0AB"
                  sx={{ marginTop: '20px', minHeight: '21px' }}
                >
                  {t('subTitle')}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  marginTop: '30px',
                  position: 'relative',
                }}
              >
                <UploadSection
                  trackEvent={trackEvent}
                  onChange={async (e: any) => {
                    setFile(e);
                    setErrorMessage('');
                  }}
                  files={file}
                />
                {errorMessage ? (
                  <div
                    style={{
                      position: 'absolute',
                      marginTop: '8px',
                    }}
                  >
                    <ErrorMessage message={errorMessage} />
                  </div>
                ) : null}
              </Grid>

              <Grid item sx={{ marginTop: '25px' }}>
                <ListItem
                  disablePadding
                  alignItems="flex-start"
                  sx={{
                    maxWidth: '100%',
                    marginTop: '20px',
                    gap: '0px',
                    paddingTop: '0px',
                    cursor: 'pointer',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      maxWidth: '24px',
                      minWidth: '24px !important',
                      maxHeight: '24px',
                      padding: 0,
                    }}
                  >
                    <Checkbox
                      onClick={() => {
                        if (!legallyAccepted) {
                          trackEvent({
                            event: 'Button click',
                            action: 'Consent check',
                          });
                        } else {
                          trackEvent({
                            event: 'Button click',
                            action: 'Consent uncheck',
                          });
                        }
                        let newValue = !legallyAccepted;
                        setLegallyAccepted(newValue);
                        localStorage.setItem('legallyAccepted', `${newValue}`);
                      }}
                      checkedIcon={
                        <img
                          style={{ width: '24px', height: '24px' }}
                          src={BlueCheckBoxIcon}
                        />
                      }
                      icon={
                        <img
                          style={{ width: '24px', height: '24px' }}
                          src={BlueUnCheckBoxIcon}
                        />
                      }
                      sx={{
                        verticalAlign: 'center',
                        width: '24px',
                        height: '24px',
                      }}
                      checked={legallyAccepted}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ marginLeft: '14px', cursor: 'default' }}>
                    <Typography
                      variant="subtitle1"
                      color="#383A49"
                      sx={{
                        textAlign: 'start',
                        fontSize: '10px',
                        fontWeight: '400',
                        lineHeight: '16px',
                        letterSpacing: '-0.3px',
                      }}
                    >
                      <Trans
                        i18nKey={'legalStatement'}
                        t={t}
                        components={{
                          underline: (
                            <a
                              onClick={() => {
                                setShowPolicy(true);
                              }}
                              style={{
                                textDecoration: 'underline',
                                color: '#0179FF',
                                cursor: 'pointer',
                                //fontSize: '12px',
                              }}
                            >
                              {t('legalStatement')}
                            </a>
                          ),
                          underlineTerms: (
                            <a
                              onClick={() => {
                                setShowTerms(true);
                              }}
                              style={{
                                textDecoration: 'none',

                                cursor: 'pointer',
                                //fontSize: '12px',
                              }}
                            >
                              {t('legalStatement')}
                            </a>
                          ),
                        }}
                      ></Trans>
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Grid>

              <Grid item sx={{ marginTop: '15px' }}>
                <PrimaryButton
                  loadingLogic={true}
                  disabled={Boolean(
                    (lastFailed && Date.now() - lastFailed < 60 * 15 * 1000) ||
                      !file ||
                      !file.length ||
                      errorMessage ||
                      progress ||
                      !legallyAccepted
                  )}
                  onClick={async () => {
                    if (isOutsideWorkHours()) {
                      setOutWorkHours(true);
                    } else {
                      trackEvent({
                        event: 'Button click',
                        action: 'Get Your Quote',
                      });
                      setIsLoading(true);
                      await uploadFile(file);
                      setIsLoading(false);
                    }
                    /**/
                  }}
                  sx={{ fontStyle: 'Semibold S' }}
                >
                  {t('uploadButton')}
                </PrimaryButton>
                <Button
                  variant="text"
                  sx={{
                    marginTop: '15px',
                    marginBottom: '15px',

                    padding: '0',
                    fontStyle: 'Semibold S',
                  }}
                  onClick={() => {
                    trackEvent({
                      event: 'Button click',
                      action: 'how it works',
                    });
                    showHowItWorks();
                  }}
                >
                  {t('howButton')}{' '}
                </Button>
              </Grid>
              <Grid item sx={{ marginTop: '15px', marginBottom: '5px' }}>
                <LanguageSection trackEvent={trackEvent} />
              </Grid>
            </Grid>

            <Grid item sx={{ marginBottom: '35px', marginTop: '20px' }}>
              {' '}
              <Footer />
            </Grid>
          </Grid>
          <Dialog
            PaperProps={{
              sx: {
                marginInline: '15px',
                width: '100%',
                borderRadius: '10px',
              },
            }}
            sx={{
              borderRadius: '10px',
              overflow: 'hidden',
              marginInline: '0px',
              width: '100%',
            }}
            open={outWorkHours}
            keepMounted
            fullWidth
            maxWidth="sm"
            onClose={() => {
              setOutWorkHours(false);
              afterDialogWorkHours();
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <ReturnButton
              style={{
                position: 'absolute',
                right: 0,
                marginRight: '15px',
                marginTop: '15px',
              }}
              close={true}
              onClick={() => {
                setOutWorkHours(false);
                afterDialogWorkHours();
              }}
            />
            <DialogContent
              sx={{
                paddingBottom: '24px',
                borderRadius: '10px',
              }}
            >
              {submittedPhoneNumber ? (
                <>
                  {' '}
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      marginTop: '30px',
                      //minWidth: '345px',
                      width: '100%',
                      //paddingInline: '24px',
                      marginBottom: '0px',
                    }}
                  >
                    <Grid item sx={{ marginBottom: '20px' }}>
                      <img
                        src={SerialSuccess}
                        srcSet={`${SerialSuccess} 1x, ${SerialSuccess2} 2x, ${SerialSuccess3} 3x`}
                        style={{
                          width: '72.45px',
                          height: '72.45px',
                        }}
                        alt=""
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h3"
                        sx={{
                          marginBottom: '15px',
                          fontWeight: '600',
                          fontSize: '17px',
                          lineHeight: '23.22px',
                          letterSpacing: '-0.3px',

                          textAlign: 'center',
                        }}
                      >
                        {t('phoneSubmittedtitle')}
                      </Typography>

                      <Typography
                        color="#9CA0AB"
                        sx={{
                          fontWeight: '500',
                          fontSize: '12px',
                          lineHeight: '18px',
                          letterSpacing: '-0.3px',
                          textAlign: 'center',
                        }}
                      >
                        {t('phoneSubmittedSubtitle')}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ marginTop: '20px', width: '100%' }}>
                      <PrimaryButton
                        sx={{ fontStyle: 'Semibold S' }}
                        onClick={() => {
                          setOutWorkHours(false);
                          afterDialogWorkHours();
                        }}
                      >
                        {t('done')}
                      </PrimaryButton>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  {' '}
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      marginTop: '30px',
                      //marginBottom: '20px',
                      width: '100%',
                    }}
                  >
                    <Grid item>
                      <img
                        src={Headphones}
                        srcSet={`${Headphones} 1x, ${Headphones2} 2x, ${Headphones3} 3x`}
                        style={{
                          width: '110px',
                          height: '96px',
                        }}
                        alt="Phone Icon"
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ paddingBottom: '15px', marginTop: '20px' }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          fontWeight: '600',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          textAlign: 'center',
                        }}
                      >
                        {t('outsideWorkhourstitle')}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ marginBottom: '15px' }}>
                      <Typography
                        variant="caption"
                        color="#9CA0AB"
                        sx={{
                          fontWeight: '500',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          textAlign: 'center',
                        }}
                      >
                        {t('outsideWorkhoursSubtitle')}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ marginBottom: '15px', width: '100%' }}>
                      <TextField
                        sx={{ minWidth: '250px', width: '100%' }}
                        defaultValue={phoneNumber}
                        label={t('phoneNumber')}
                        fullWidth
                        value={phoneNumber}
                        onChange={(event) => {
                          setPhoneNumber(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item sx={{ marginBottom: '20px', width: '100%' }}>
                      <PrimaryButton
                        sx={{ fontStyle: 'Semibold S' }}
                        loadingLogic={true}
                        disabled={phoneNumber.length < 6}
                        onClick={async () => {
                          try {
                            setIsLoading(true);
                            const embeddedInsuranceService =
                              new EmbeddedInsuranceService();
                            await embeddedInsuranceService.notifyPhoneNumber(
                              phoneNumber
                            );
                            setSubmittedPhoneNumber(true);
                          } catch (err) {
                          } finally {
                            setIsLoading(false);
                          }
                        }}
                      >
                        {t('submit')}
                      </PrimaryButton>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        sx={{
                          marginBottom: '0px',
                          color: '#383A49',
                          padding: '0',
                          fontStyle: 'Semibold S',
                        }}
                        onClick={() => {
                          setOutWorkHours(false);
                          afterDialogWorkHours();
                        }}
                      >
                        {tcommon('cancel')}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </DialogContent>
          </Dialog>
          <SupportSheet open={showSheet} setOpen={setShowSheet} width={width} />
          <CookiesSheet />
        </>
      );
  }

  return null;
}

export default InsureProductPage;
