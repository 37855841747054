import Theft from '../../assets/insurance/theft.svg';
import Loss from '../../assets/insurance/loss.svg';
import Damage from '../../assets/insurance/damage.svg';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
export type InsuranceTypesProps = { filterTypes?: string[] };

export const InsuranceTypes: React.FC<InsuranceTypesProps> = (props) => {
  const { t } = useTranslation(namespaces.pages.processingPage);
  const insuranceOptions = [
    {
      title: t('features.loss.title'),
      description: t('features.loss.description'),
      image: Loss,
    },
    {
      title: t('features.damage.title'),
      description: t('features.damage.description'),
      image: Damage,
    },
    {
      title: t('features.theft.title'),
      description: t('features.theft.description'),
      image: Theft,
      isOptionnal: true,
    },
  ];

  return (
    <>
      <List
        sx={{
          width: '100%',
          maxWidth: '100%',
          bgcolor: 'background.paper',
          gap: '30px',
          padding: '0px',
        }}
      >
        {' '}
        {insuranceOptions.map((insuranceOption, index) => (
          <ListItem
            alignItems="flex-start"
            key={index}
            sx={{ padding: '0px', marginBottom: '23px' }}
          >
            <ListItemAvatar sx={{ minWidth: '50px !important' }}>
              <Avatar
                src={insuranceOption.image}
                sx={{ height: '38px', width: '40px' }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <div style={{ height: '26px' }}>
                  <Typography variant="body1">
                    {insuranceOption.isOptionnal ? (
                      <>
                        {' '}
                        {insuranceOption.title + ' '}
                        <Typography
                          variant="body1"
                          color="#9CA0AB"
                          sx={{ display: 'inline-block' }}
                        >
                          {' ' + t('isOptionnal')}
                        </Typography>
                      </>
                    ) : (
                      insuranceOption.title
                    )}
                  </Typography>
                </div>
              }
              secondary={
                <div
                  style={{
                    height: '23px',
                    marginTop: '5px !important',
                  }}
                >
                  <Typography
                    sx={{
                      display: 'inline',
                    }}
                    component="span"
                    variant="subtitle1"
                    color="#9CA0AB"
                  >
                    {insuranceOption.description}
                  </Typography>
                </div>
              }
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default InsuranceTypes;
