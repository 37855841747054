import i18next, { i18n as i18nInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { languages, namespaces } from './i18n.constants';
import HttpApi from 'i18next-http-backend';

const createI18n = (language: string): i18nInstance => {
  const i18n = i18next.createInstance().use(initReactI18next);

  i18n
    .use(HttpApi) // Use backend plugin for translation file download.
    .use(LanguageDetector)
    .init(
      {
        backend: {
          loadPath: './assets/locales/{{lng}}/{{ns}}.json', // Specify where backend will find translation files.
        },
        lng: localStorage.getItem('lang') || 'de',
        fallbackLng: language,
        ns: namespaces.common,
        react: {
          transSupportBasicHtmlNodes: true,
        },
        defaultNS: 'translations',
        missingKeyHandler: function (
          lngs,
          ns,
          key,
          fallbackValue,
          updateMissing,
          options
        ) {
          console.log(key);
          return key;
        },
      },
      () => {}
    );

  return i18n;
};

export const i18n = createI18n(localStorage.getItem('lang') || languages.de);
