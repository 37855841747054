import { Typography } from '@mui/material';
import { useContext } from 'react';
import ElementLogo from '../../assets/insurance/ElementLogo.png';
import ElementLogo2 from '../../assets/insurance/ElementLogo@2x.png';
import ElementLogo3 from '../../assets/insurance/ElementLogo@3x.png';
import InsureProductProcessContext from '../../contexts/InsureProductProcessContext';
export const LongFooter = (props: any) => {
  const { tcommon: t } = useContext(InsureProductProcessContext);
  return (
    <>
      <Typography
        sx={{
          fontFamily: 'Avenir Next',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: ' 21px',
          textAlign: 'center',
          letterSpacing: '-0.3px',
          color: '#9CA0AB',
          marginTop: '16px',
          marginBottom: '10px',
        }}
      >
        {t('shortFooter')}
        <br />
        <img
          src={ElementLogo}
          srcSet={`${ElementLogo} 1x, ${ElementLogo2} 2x, ${ElementLogo3} 3x`}
          style={{
            marginTop: '13px',
            width: '101px',
            height: '26px',
          }}
        />
      </Typography>
    </>
  );
};
