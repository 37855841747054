import { useTranslation, Trans } from 'react-i18next';
import { Grid, Link, Typography } from '@mui/material';
import { namespaces } from '@zenown-insurance/i18n-service';
import ReturnButton from '../return-button/ReturnButton';
import RVLogo from '../../assets/insurance/RV.png';
import RVLogo2 from '../../assets/insurance/RV@2x.png';
import RVLogo3 from '../../assets/insurance/RV@3x.png';
import { useEffect } from 'react';

export interface PolicyPageProps {
  onClose: () => void;
}

export function PolicyPage(props: any) {
  const { onClose, trackEvent } = props;

  const { t: tupload } = useTranslation(namespaces.pages.uplaodPage);
  const t = (key: string) => tupload('policyPage.' + key);
  const { t: common } = useTranslation(namespaces.common);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const renderSections = () => {
    const sectionTexts = PolicyParagraps.map(
      (paragraph: { title: string; description: string }, index: number) => {
        return (
          <Grid item key={index + ''}>
            <Typography sx={{ marginBottom: '20px' }} variant="h3">
              {index + 1 + '.  ' + t('paragraphs.' + paragraph.title)}
            </Typography>
            <Typography variant="subtitle1">
              <Trans
                i18nKey={'paragraphs.' + paragraph.description}
                t={t}
                components={{
                  link: (
                    <a
                      style={{
                        color: '#0179FF !important',
                        cursor: 'pointer',
                      }}
                      href={
                        t('paragraphs.' + paragraph.description)?.includes('@')
                          ? 'mailto:' + t('paragraphs.' + paragraph.description)
                          : '' + t('paragraphs.' + paragraph.description)
                      }
                      color="#0179FF"
                    >
                      {t('paragraphs.' + paragraph.description)}
                    </a>
                  ),
                  newLine: <br />,
                  tab: <p>{'    '}</p>,
                  underline: (
                    <span style={{ textDecoration: 'underline' }}>
                      {t('paragraphs.' + paragraph.description)}
                    </span>
                  ),
                  strong: (
                    <strong>{t('paragraphs.' + paragraph.description)}</strong>
                  ),
                  email: (
                    <a
                      style={{ color: '#0179FF' }}
                      color="#0179FF"
                      href={
                        t('paragraphs.' + paragraph.description)?.includes('@')
                          ? 'mailto:' + t('paragraphs.' + paragraph.description)
                          : '' + t('paragraphs.' + paragraph.description)
                      }
                    >
                      {t('paragraphs.' + paragraph.description)}
                    </a>
                  ),
                }}
              />
            </Typography>
          </Grid>
        );
      }
    );

    return sectionTexts;
  };

  return (
    <div style={{ position: 'relative' }}>
      <ReturnButton
        onClick={() => {
          trackEvent({
            event: 'Button click',
            action: 'Back',
          });
          onClose();
        }}
      />
      {false && (
        <img
          src={RVLogo}
          srcSet={`${RVLogo} 1x, ${RVLogo2} 2x, ${RVLogo3} 3x`}
          style={{
            height: '22px',
            width: '56px',
            right: '0',
            top: '-45px',
            position: 'absolute',
          }}
          alt="RV Logo"
        />
      )}

      <div
        style={{
          height: 'auto',
          minWidth: '330px',
          overflowY: 'scroll',
          marginTop: '88px',
          marginBottom: '88px',
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ gap: '30px' }}
        >
          <Grid
            item
            container
            direction="column"
            justifyContent="space-between"
            spacing={1.5}
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h1" sx={{ marginBottom: '20px' }}>
                {t('title')}
              </Typography>
              <Typography variant="subtitle1">
                <Trans
                  i18nKey="description"
                  t={t}
                  components={{
                    link: (
                      <a
                        href={
                          t('description')?.includes('@')
                            ? 'mailto:' + t('description')
                            : '' + t('description')
                        }
                        style={{
                          color: '#0179FF !important',
                          cursor: 'pointer',
                        }}
                        color="#0179FF"
                      >
                        {t('description')}
                      </a>
                    ),
                    newLine: <br />,
                    tab: <p>{'    '}</p>,
                    underline: (
                      <span style={{ textDecoration: 'underline' }}>
                        {t('description')}
                      </span>
                    ),
                    strong: <strong>{t('description')}</strong>,
                    email: (
                      <a
                        style={{ color: '#383A49' }}
                        color="#383A49"
                        href={'mailto:' + t('description')}
                      >
                        {t('description')}
                      </a>
                    ),
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            justifyContent="space-around"
            sx={{ gap: '30px' }}
            alignItems="flex-start"
          >
            {renderSections()}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default PolicyPage;
const PolicyParagraps = [
  {
    title: 'p1.title',
    description: 'p1.description',
  },
  {
    title: 'p2.title',
    description: 'p2.description',
  },
  {
    title: 'p3.title',
    description: 'p3.description',
  },
  {
    title: 'p4.title',
    description: 'p4.description',
  },
  {
    title: 'p5.title',
    description: 'p5.description',
  },
  {
    title: 'p6.title',
    description: 'p6.description',
  },
  {
    title: 'p7.title',
    description: 'p7.description',
  },
  {
    title: 'p8.title',
    description: 'p8.description',
  },
  {
    title: 'p9.title',
    description: 'p9.description',
  },
  {
    title: 'p10.title',
    description: 'p10.description',
  },
  {
    title: 'p11.title',
    description: 'p11.description',
  },
  {
    title: 'p12.title',
    description: 'p12.description',
  },
  {
    title: 'p13.title',
    description: 'p13.description',
  },
  {
    title: 'p14.title',
    description: 'p14.description',
  },
  {
    title: 'p15.title',
    description: 'p15.description',
  },
];
