
import LogoHeader from './LogoHeader';

export type UploadPageHeaderProps = {
  productImage?: string;
};

export const UploadPageHeader: React.FC<UploadPageHeaderProps> = () => {
  return (
    <div
      style={{
        width: '100%',
        minWidth: '100%',
      }}
    >
      <LogoHeader />
    </div>
  );
};

export default UploadPageHeader;
