import { Trans, useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import {
  Grid,
  Typography,
  Card,
  Link,
  Divider,
  Checkbox,
  ListItem,
  IconButton,
  ListItemIcon,
  Dialog,
  ListItemText,
  Button,
  Paper,
  List,
  ListItemSecondaryAction,
  InputAdornment,
  TextField,
  ListItemAvatar,
  ButtonBase,
  Box,
  DialogContent,
  CircularProgress,
} from '@mui/material';
import './CalculatorScreen.css';
import ArrowRightReceipt from '../../../../assets/insurance/ArrowRightReceipt (2).svg';
import ArrowRightReceiptWhite from '../../../../assets/insurance/ArrowRightReceipt.svg';

import BlueUnCheckBoxIcon from '../../../../assets/insurance/blueUnCheckBoxIcon.svg';
import BlueCheckBoxIcon from '../../../../assets/insurance/blueCheckBoxIcon.svg';
import { getPriceFormatted } from '@zenown-insurance/services/requests';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReturnButton from './../../../../components/return-button/ReturnButton';
import BikeIcon from './../../../../assets/insurance/bike-icon.svg';
import SheildIcon from './../../../../assets/insurance/sheild-icon.svg';
import ZenOwnLogo from '../../../../assets/insurance/ZenOwn.png';
import ZenOwnLogo2 from '../../../../assets/insurance/ZenOwn@2x.png';
import ZenOwnLogo3 from '../../../../assets/insurance/ZenOwn@3x.png';
import PlatCheck from '../../../../assets/insurance/platCheck@3x.png';
import Sheet from 'react-modal-sheet';
import Receipt from '../../../../assets/insurance/receipt.png';
import Receipt2 from '../../../../assets/insurance/receipt@2x.png';
import Receipt3 from '../../../../assets/insurance/receipt@3x.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PrimaryButton from '../../../../../lib/components/primary-button/PrimaryButton';
import SecondaryButton from '../../../../../lib/components/secondary-button/SecondaryButton';
import { useContext, useCallback, useRef, useState, useEffect } from 'react';
import { LogoHeader } from '../../../../../lib/components/zenown-insurance';
import InsureProductProcessContext from '../../../../../lib/contexts/InsureProductProcessContext';
import moment from 'moment';
import { getCurrencyText } from '@zenown-insurance/services/requests';
import { LongFooter } from 'libs/ui/src/lib/components/zenown-insurance/LongFooter';
import DisabledCheckedIcon from './../../../../assets/insurance/disabled-checked-icon.svg';
import CheckedIcon from './../../../../assets/insurance/checked-icon.svg';
import { Footer } from 'libs/ui/src/lib/components/footer/Footer';
import { styled } from '@mui/system';
import { UploadSection } from '../../../../components/zenown-insurance/UploadSection';
import { isOutsideWorkHours } from '../InsureProductPage';
import SerialSuccess from '../../../../assets/insurance/serial-success.png';
import SerialSuccess2 from '../../../../assets/insurance/serial-success@2x.png';
import SerialSuccess3 from '../../../../assets/insurance/serial-success@3x.png';
import { EmbeddedInsuranceService } from '@zenown-insurance/services/requests';
const embeddedInsuranceService = new EmbeddedInsuranceService();
import { debounce } from 'lodash';
import Headphones from '../../../../assets/insurance/Headphones.png';
import Headphones2 from '../../../../assets/insurance/Headphones@2x.png';
import Headphones3 from '../../../../assets/insurance/Headphones@3x.png';
import ErrorMessage from 'libs/ui/src/lib/components/error-message/ErrorMessage';
import { LanguageSection } from 'libs/ui/src/lib/components/zenown-insurance/LanguageSection';
export function CalculatorScreen(props: any) {
  const {
    calculatorModalOpen,
    tUpload,
    file,
    setFile,
    trackEvent,
    outWorkHours,
    setOutWorkHours,
    showHowItWorks,
    setShowPolicy,
    setShowTerms,
    isLoading,
    setIsLoading,
    progress,
    errorMessage,
    setIntialPremium,
    uploadFile,
  } = useContext(InsureProductProcessContext);

  const t = (key: string) => tUpload('calculator.' + key);
  const [width, setWidth] = useState(375);
  const [error, setError] = useState<any>(undefined);
  const [uploadingFile, setUploadingFile] = useState(false);

  useEffect(() => {
    const element = document.getElementById('layout-box');
    if (element) {
      const positionInfo = element?.getBoundingClientRect();
      setWidth(positionInfo.width);
    }
    document.title = t('title');
    console.log(`Is outside of working hours ${isOutsideWorkHours()}`);
    // eslint-disable-next-line
  }, []);
  const scroll = useRef<any>();
  const [submittedPhoneNumber, setSubmittedPhoneNumber] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('+49 ');

  const [legallyAccepted, setLegallyAccepted] = useState(
    localStorage.getItem('legallyAccepted') === 'true'
  );
  const [scrolling, setScrolling] = useState(true);
  const [sheetOpen, setSheetOpen] = useState(false);
  const [price, setPrice] = useState<any>();
  const [offres, setOffres] = useState<any>([]);
  const [inputFocused, setInputFocused] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [submittedPrice, setSubmittedPrice] = useState(false);
  const insuranceFeatures = [
    {
      description: t('benefitsRisksList.1'),
    },
    {
      description: t('benefitsRisksList.2'),
    },
    {
      description: t('benefitsRisksList.3'),
    },
    {
      description: t('benefitsRisksList.4'),
    },
    {
      description: t('benefitsRisksList.5'),
    },
    {
      description: t('benefitsRisksList.6'),
    },
    {
      description: t('benefitsRisksList.7'),
    },
  ];
  const [seefull, setSeeFull] = useState(false);
  const getPremiumOptions = async (price: number) => {
    try {
      setIsLoading(true);
      // Assuming the implementation of the getPremiumOptions API call
      const response = await embeddedInsuranceService.getPremiumOptions(price);
      if (response?.status === 'success') {
        setOffres(response.data);
        setSubmittedPrice(true);
        /*   setTimeout(() => {
            let doc = document.getElementById('result-quotes');
            doc?.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
          }, 0);*/
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };
  const choosePlan = (isPremium: boolean) => {
    setIntialPremium(isPremium);
    localStorage.setItem('intialPremium', isPremium + '');
    setSheetOpen(true);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingInline: '16px',
      }}
    >
      <img
        src={ZenOwnLogo}
        srcSet={`${ZenOwnLogo} 1x, ${ZenOwnLogo2} 2x, ${ZenOwnLogo3} 3x`}
        style={{
          width: '154.52px',
          height: '33px',
          marginTop: '24px',
        }}
      />
      <div
        style={{
          marginTop: '20px',
          width: 'calc(100% - 32px)',
          background: '#FFFFFF',

          /* Drop Shadow */
          padding: '24px',

          boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
          borderRadius: '10px',
        }}
      >
        <div
          style={{ textAlign: 'center', width: '100%', marginBottom: '24px' }}
        >
          <Typography
            variant="h1"
            sx={{
              marginInline: 'auto',
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '44.5px',
            }}
          >
            {t('title')}
          </Typography>
          <Typography
            variant="h3"
            sx={{
              marginInline: 'auto',
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '20.8px',
              color: '#9CA0AB',
              marginTop: '8px',
            }}
          >
            {t('subTitle')}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '8px',
            maxHeight: '57px',
            minHeight: '57px',
          }}
        >
          {' '}
          <div
            className={inputFocused ? 'focused-input' : ''}
            style={{
              position: 'relative',
              background: '#FFFFFF',
              padding: '16.3px',
              paddingInline: '16px',
              paddingRight: '50px',
              maxHeight: '22.8px',
              minHeight: '22.8px',

              /* Zen Sub-Text */

              border: '1px solid #9CA0AB',
              borderRadius: '8px',

              width: 'calc(100% - 66px)',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '20.8px',
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                marginInline: 'auto',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '20.8px',
              }}
              style={{
                position: 'absolute',
                right: '18px',
                top: '19px',
                border: 'none',
                zIndex: 99,
              }}
            >
              EUR
            </Typography>
            <input
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              onFocus={() => {
                setInputFocused(true);
              }}
              onBlur={() => {
                setInputFocused(false);
              }}
              placeholder="0"
              style={{
                outline: 'none',
                width: 'calc(100% - 50px)',

                background: '#FFFFFF',
                /* Zen Sub-Text */

                border: 'none',

                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '20.8px',
              }}
              value={price}
              onChange={(e: any) => {
                trackEvent({
                  event: 'Input',
                  action: 'Price calculator',
                });
                let p = e.target.value
                  .replace(/[^0-9.,]+/g, '')
                  .replace('.', ',');

                if (e.target.value) {
                  setPrice(p);
                  setError(undefined);
                  //getPremiumOptions(+p.replace(',', '.'));
                } else {
                  setPrice(undefined);
                  setOffres([]);
                }
              }}
            />
          </div>
          {Boolean(price && !(sheetOpen || offres?.length)) && (
            <Button
              variant="contained"
              disabled={error?.length}
              sx={{
                width: '57px',
                height: '57px',
                borderRadius: '10px',
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none', // Disable shadow on hover
                },
                '&:click': {
                  boxShadow: 'none', // Disable shadow on hover
                },
              }}
              onClick={() => {
                getPremiumOptions(+price.replace(',', '.'));
              }}
            >
              {isLoading ? (
                <>
                  <CircularProgress
                    size={20}
                    sx={{ color: 'white', margin: 'auto' }}
                  />
                </>
              ) : (
                <>
                  {' '}
                  <img
                    src={ArrowRightReceipt}
                    style={{
                      width: '14px',
                      height: '11px',
                    }}
                  />
                </>
              )}
            </Button>
          )}
        </div>
        {!offres.length && !sheetOpen && (
          <>
            {' '}
            <Divider
              style={{
                margin: '24px',
                marginInline: '0',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '12px',
                  lineHeight: '15.6px',
                  color: '#9CA0AB',
                  opacity: '0.68',
                  verticalAlign: 'middle',
                }}
              >
                {t('or')}
              </Typography>
            </Divider>
            {file?.length ? (
              <div
                style={{
                  width: '100%',
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSheetOpen(true);
                }}
              >
                <UploadSection
                  id="uploadSection"
                  key={'00'}
                  trackEvent={trackEvent}
                  onChange={async (e: any) => {
                    if (e && typeof e?.preventDefault == 'function')
                      e?.preventDefault();
                    setFile(e);
                    setUploadingFile(false);
                  }}
                  files={file}
                />
              </div>
            ) : (
              <>
                <ButtonBase
                  onClick={() => {
                    setSheetOpen(true);
                  }}
                  sx={{
                    padding: 0,
                    width: '100%',
                    height: '100%',
                    display: 'block',
                    borderRadius: '12px',
                  }}
                >
                  <List sx={{ padding: '0', listStyle: 'none !important' }}>
                    {' '}
                    <ListItem
                      sx={{
                        alignItems: 'center',
                        listStyle: 'none !important',
                      }}
                      alignItems="center"
                    >
                      <ListItemAvatar>
                        <img
                          src={Receipt}
                          srcSet={`${Receipt} 1x, ${Receipt2} 2x, ${Receipt3} 3x`}
                          style={{
                            width: '30px',
                            height: '34px',
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              marginTop: '3px',
                              color: '#0179FF',
                              fontSize: '16px',
                              fontWeight: '600',
                              lineHeight: '20.8px',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('receipt.title')}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{
                              color: '#9CA0AB',
                              fontSize: '14px',
                              fontWeight: '500',
                              lineHeight: '18.2px',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('receipt.subTitle')}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction sx={{ right: '8px' }}>
                        <img
                          src={ArrowRightReceiptWhite}
                          style={{
                            width: '14px',
                            height: '11px',
                          }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>{' '}
                </ButtonBase>
                {Boolean(error || errorMessage?.length) ? (
                  <div
                    style={{
                      marginTop: '8px',
                    }}
                  >
                    <ErrorMessage message={error ? error : errorMessage} />
                  </div>
                ) : null}
              </>
            )}
          </>
        )}
      </div>{' '}
      <Typography variant="h2" sx={{ marginTop: '20px', width: '100%' }}>
        {t('benefitsRisksList.title')}
      </Typography>
      <ButtonBase sx={{ width: '100%' }} onClick={() => setSeeFull(!seefull)}>
        {' '}
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          {' '}
          <List
            ref={scroll}
            onScroll={() => {
              setScrolling(scrolling);
              console.log(scroll.current.scrollTop);
            }}
            sx={{
              padding: 0,
              maxHeight: !seefull ? '160px' : '',
              overflowY: 'clip',
              position: 'relative',
              marginTop: '6px',
              width: '100%',
              paddingBottom: '30px',
            }}
          >
            {insuranceFeatures.map((feat, index) => (
              <ListItem
                alignItems="center"
                key={index}
                sx={{ padding: '0px', marginTop: '15px' }}
              >
                <ListItemText
                  sx={{ margin: '0' }}
                  primary={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <img
                        src={index < 2 ? PlatCheck : CheckedIcon}
                        style={{
                          height: '20px',
                          width: '20px',
                          marginRight: '10px',
                          verticalAlign: 'middle',
                        }}
                        alt="Checkmark icon"
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          flexGrow: 1,
                        }}
                      >
                        {' '}
                        <Typography
                          variant="caption"
                          sx={{
                            fontWeight: '500!important',
                            letterSpacing: '-0.3px!important',
                            fontSize: '12.5px !important',
                            lineHeight: '18.2px!important',
                          }}
                        >
                          {feat.description}
                        </Typography>
                        {index < 2 && (
                          <div
                            style={{
                              padding: '1px',
                              maxHeight: '18px',
                              paddingInline: '0px',
                              color: 'white',
                              fontWeight: '500',
                              fontSize: '12px',
                              lineHeight: '18px',
                              maxWidth: '110px',
                              minWidth: '110px',
                              textAlign: 'center',
                              background:
                                'radial-gradient(148.08% 148.08% at 0% -21.15%, #EBECEF 0%, #8DA4D2 100%)',
                              borderRadius: '4px',
                            }}
                          >
                            {t('benefitsRisksList.plat')}
                          </div>
                        )}
                      </div>
                    </div>
                  }
                />
              </ListItem>
            ))}
          </List>{' '}
          {!seefull && (
            <div
              style={{
                position: 'absolute',
                bottom: '0px',
                width: '100%',
                left: '0',
                zIndex: '99',
                height: '112px',

                background:
                  'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
                transform: 'rotate(180deg)',
              }}
            ></div>
          )}
        </div>
      </ButtonBase>
      {
        <div style={{ textAlign: 'left', width: '100%' }}>
          <Button
            variant="text"
            onClick={() => setSeeFull(!seefull)}
            sx={{ paddingInline: '1px' }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: '600',
                color: '#0179FF',
              }}
            >
              {!seefull ? t('seeFullCoverage') : t('hideFullCoverage')}
            </Typography>
          </Button>
        </div>
      }
      <div style={{ marginTop: '10px' }}>
        <LanguageSection trackEvent={trackEvent} />
      </div>
      <div style={{ marginTop: '15px' }}>
        <Button
          variant="text"
          sx={{
            marginTop: '15px',
            marginBottom: '15px',

            padding: '0',
            fontStyle: 'Semibold S',
          }}
          onClick={() => {
            showHowItWorks();
          }}
        >
          {tUpload('howButton')}{' '}
        </Button>
      </div>
      {Boolean(offres?.length && !isLoading) && (
        <>
          <div
            onClick={() => setOffres([])}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 2,
              opacity: '0.3',
              backgroundColor: 'transparent',
            }}
          ></div>{' '}
          <Sheet
            style={{
              maxWidth: '500px',
              margin: '0 auto',
              zIndex: 999,
              padding: 0,
            }}
            isOpen={offres?.length}
            disableDrag={true}
            onClose={() => {
              setOffres([]);
            }}
          >
            <Sheet.Container
              style={{
                maxWidth: '500px',
                margin: '0 auto',
                zIndex: 999,
                height: '226px',
                maxHeight: '226px',
                padding: 0,
                borderRadius: 0,
                //padding: '16px',
              }}
            >
              <Sheet.Header
                style={{
                  height: 0,
                  display: 'none',
                }}
              />
              <Sheet.Content style={{ padding: '16px' }}>
                <div
                  style={{
                    borderTop: '1px solid #DFE0E5',
                    background: 'white',
                    zIndex: '99',
                    padding: '15px',
                    paddingTop: '22px',
                    paddingBottom: '0px',
                    /* Drop Shadow */

                    //boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
                    //width: 'calc( 100% + 30px )',

                    //boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      gap: '8px',
                      position: 'relative',
                    }}
                  >
                    <div className="gold-choose">
                      <div
                        style={{
                          borderRadius: '12px',
                          padding: '14px',
                          paddingInline: '12px',
                          height: '48px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          background: 'white',
                        }}
                      >
                        {' '}
                        <ListItem
                          sx={{
                            padding: 0,
                            marinTop: '5px',
                            justifyContent: 'space-between',
                            width: '100%',
                            height: '48px',
                            maxHeight: '48px',
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  lineHeight: '19px',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {t('gold')}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                sx={{
                                  fontSize: '20px',
                                  fontWeight: '700',
                                  lineHeight: '28px',
                                }}
                              >
                                {'€' + offres[0]?.premium}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <Button
                          sx={{
                            background:
                              'radial-gradient(148.08% 148.08% at 0% -21.15%, #F5F0D8 0%, #CF9828 100%)',
                            color: 'white',
                            padding: '13px',
                            paddingInline: '29px',
                            borderRadius: '10px',
                            height: '48px',
                            width: '250px',
                            textAlign: 'center',
                          }}
                          onClick={() => {
                            choosePlan(false);
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: '600',
                              lineHeight: '21px',
                              color: 'white',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('goldchoose')}{' '}
                          </Typography>

                          <ArrowForwardIosIcon
                            sx={{
                              color: 'white',
                              height: '12px',
                              marginLeft: '10px',
                            }}
                          />
                        </Button>
                      </div>
                    </div>
                    <div className="plat-choose">
                      <div
                        style={{
                          borderRadius: '12px',
                          padding: '14px',
                          paddingInline: '12px',
                          height: '48px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          background: 'white',
                        }}
                      >
                        {' '}
                        <ListItem
                          sx={{
                            padding: 0,
                            marinTop: '5px',
                            justifyContent: 'space-between',
                            width: '100%',
                            height: '48px',
                            maxHeight: '48px',
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  lineHeight: '19px',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {t('plat')}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                sx={{
                                  fontSize: '20px',
                                  fontWeight: '700',
                                  lineHeight: '28px',
                                }}
                              >
                                {'€' + offres[1]?.premium}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <Button
                          sx={{
                            background:
                              'radial-gradient(148.08% 148.08% at 0% -21.15%, #EBECEF 0%, #8DA4D2 100%)',
                            color: 'white',
                            padding: '13px',
                            paddingInline: '29px',
                            borderRadius: '10px',
                            height: '48px',
                            width: '250px',
                            textAlign: 'center',
                          }}
                          onClick={() => {
                            choosePlan(true);
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: '600',
                              lineHeight: '21px',
                              whiteSpace: 'nowrap',
                              color: 'white',
                            }}
                          >
                            {t('platchoose')}{' '}
                          </Typography>

                          <ArrowForwardIosIcon
                            sx={{
                              color: 'white',
                              height: '12px',
                              marginLeft: '10px',
                            }}
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      marginInline: 'auto',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '21px',
                    }}
                    style={{
                      textAlign: 'center',
                      marginTop: '15px',
                      width: '100%',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '21px',
                    }}
                  >
                    <span style={{ color: '#0179FF' }}>* </span>
                    {t('1year')}
                  </Typography>
                </div>
              </Sheet.Content>
            </Sheet.Container>

            <Sheet.Backdrop />
          </Sheet>
        </>
      )}
      {sheetOpen && (
        <>
          <div
            onClick={() => setSheetOpen(false)}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 2,
              opacity: '0.3',
              backgroundColor: 'transparent',
            }}
          ></div>
          <Sheet
            style={{ maxWidth: '500px', margin: '0 auto', zIndex: 999 }}
            isOpen={sheetOpen}
            onClose={() => {
              setSheetOpen(false);
            }}
          >
            <Sheet.Container
              style={{
                maxHeight: errorMessage
                  ? !file?.length && !uploadingFile
                    ? '430px'
                    : '380px'
                  : !file?.length && !uploadingFile
                  ? '410px'
                  : '360px',
                //paddingInline: '16px',
              }}
            >
              <Sheet.Header
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  paddingTop: '12px',
                  height: '5px',
                  maxHeight: '5px',
                }}
              />
              <Sheet.Content style={{}}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingInline: '16px',
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      marginInline: 'auto',
                      fontSize: '24px',
                      fontWeight: '700',
                      lineHeight: '32px',
                      marginBottom: '24px',
                      marginTop: '16px',
                    }}
                  >
                    {t('receipt.sub')}
                  </Typography>
                  {Boolean(!file?.length && !uploadingFile) && (
                    <>
                      {' '}
                      <ButtonBase
                        onClick={() => {
                          let doc = document.getElementById('uploadSection');
                          if (doc) doc.click();
                        }}
                        sx={{
                          padding: 0,
                          width: '100%',
                          height: '100%',
                          display: 'block',
                        }}
                      >
                        {' '}
                        <div
                          style={{
                            boxSizing: 'border-box',

                            height: '138px',
                            width: '100%',

                            /* primary/20 */

                            background: '#F8FBFF',
                            /* primary/60 */

                            border: '2px dashed #E0EFFF',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <img
                            src={Receipt}
                            srcSet={`${Receipt} 1x, ${Receipt2} 2x, ${Receipt3} 3x`}
                            style={{
                              width: '30px',
                              height: '34px',
                              marginTop: '14px',
                            }}
                          />
                          <Typography
                            sx={{
                              marginTop: '14px',
                              fontSize: '14px',
                              fontWeight: '600',
                              lineHeight: '21px',
                            }}
                          >
                            {t('receipt.title')}
                          </Typography>
                          <Typography
                            sx={{
                              color: '#9CA0AB',
                              marginTop: '2px',
                              fontSize: '12px',
                              fontWeight: '500',
                              lineHeight: '17.2px',
                              maxWidth: '160px',
                            }}
                          >
                            {t('receipt.subTitle')}
                          </Typography>
                        </div>
                      </ButtonBase>
                    </>
                  )}
                  {
                    <div
                      style={
                        file?.length || uploadingFile
                          ? {
                              width: '100%',
                              marginTop: '24px',
                              position: 'relative',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }
                          : { display: 'none' }
                      }
                    >
                      <UploadSection
                        id="uploadSection"
                        setUploadingFile={setUploadingFile}
                        trackEvent={trackEvent}
                        onChange={async (e: any) => {
                          setFile(e);
                          setUploadingFile(false);
                        }}
                        files={file}
                      />
                    </div>
                  }
                  {Boolean(error || errorMessage?.length) ? (
                    <div
                      style={{
                        marginTop: '8px',
                      }}
                    >
                      <ErrorMessage message={error ? error : errorMessage} />
                    </div>
                  ) : null}
                  <ListItem
                    disablePadding
                    alignItems="flex-start"
                    sx={{
                      maxWidth: '100%',
                      marginTop: '20px',
                      gap: '0px',
                      paddingTop: '0px',
                      cursor: isLoading ? 'default' : 'pointer',
                      pointerEvents: isLoading ? 'none' : 'auto',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        maxWidth: '24px',
                        minWidth: '24px !important',
                        maxHeight: '24px',
                        padding: 0,
                      }}
                    >
                      <Checkbox
                        onClick={() => {
                          if (!legallyAccepted) {
                            trackEvent({
                              event: 'Button click',
                              action: 'Consent check',
                            });
                          } else {
                            trackEvent({
                              event: 'Button click',
                              action: 'Consent uncheck',
                            });
                          }
                          let newValue = !legallyAccepted;
                          setLegallyAccepted(newValue);
                          localStorage.setItem(
                            'legallyAccepted',
                            `${newValue}`
                          );
                        }}
                        checkedIcon={
                          <img
                            style={{ width: '24px', height: '24px' }}
                            src={BlueCheckBoxIcon}
                          />
                        }
                        icon={
                          <div
                            style={{
                              width: '24px',
                              height: '24px',
                              minWidth: '24px',
                              border: '1px solid #9CA0AB',
                              borderRadius: '4px',
                            }}
                            //src={BlueUnCheckBoxIcon}
                          />
                        }
                        sx={{
                          verticalAlign: 'center',
                          width: '24px',
                          height: '24px',
                        }}
                        checked={legallyAccepted}
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        marginLeft: '14px',
                        cursor: 'default',
                        marginTop: '7px',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color="#383A49"
                        sx={{
                          textAlign: 'start',
                          fontSize: '12px',
                          fontWeight: '400',
                          lineHeight: '17.5px',
                          letterSpacing: '-0.3px',
                        }}
                      >
                        <Trans
                          i18nKey={'legalStatement'}
                          t={t}
                          components={{
                            underline: (
                              <a
                                onClick={() => {
                                  setShowPolicy(true);
                                }}
                                style={{
                                  textDecoration: 'underline',
                                  color: '#0179FF',
                                  cursor: 'pointer',

                                  //fontSize: '12px',
                                }}
                              >
                                {t('legalStatement')}
                              </a>
                            ),
                            underlineTerms: (
                              <a
                                onClick={() => {
                                  setShowTerms(true);
                                }}
                                style={{
                                  textDecoration: 'none',

                                  cursor: 'pointer',
                                  //fontSize: '12px',
                                }}
                              >
                                {t('legalStatement')}
                              </a>
                            ),
                          }}
                        ></Trans>
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <PrimaryButton
                    loadingLogic={true}
                    disabled={Boolean(
                      !file ||
                        !file.length ||
                        errorMessage ||
                        progress ||
                        !legallyAccepted
                    )}
                    onClick={async () => {
                      if (isOutsideWorkHours()) {
                        setOutWorkHours(true);
                      } else {
                        trackEvent({
                          event: 'Button click',
                          action: 'Get Your Quote',
                        });
                        setIsLoading(true);
                        await uploadFile(file);
                        setIsLoading(false);
                      }
                      /**/
                    }}
                    sx={{ fontStyle: 'Semibold S', marginTop: '24px' }}
                  >
                    {t('uploadButton')}
                  </PrimaryButton>
                </div>
              </Sheet.Content>
            </Sheet.Container>

            <Sheet.Backdrop />
          </Sheet>
        </>
      )}
      <Dialog
        PaperProps={{
          sx: {
            marginInline: '15px',
            width: '100%',
            borderRadius: '10px',
          },
        }}
        sx={{
          borderRadius: '10px',
          overflow: 'hidden',
          marginInline: '0px',
          width: '100%',
        }}
        open={outWorkHours}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setOutWorkHours(false);
          //afterDialogWorkHours();
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <ReturnButton
          style={{
            position: 'absolute',
            right: 0,
            marginRight: '15px',
            marginTop: '15px',
          }}
          close={true}
          onClick={() => {
            setOutWorkHours(false);
            //afterDialogWorkHours();
          }}
        />
        <DialogContent
          sx={{
            paddingBottom: '24px',
            borderRadius: '10px',
          }}
        >
          {submittedPhoneNumber ? (
            <>
              {' '}
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  marginTop: '30px',
                  //minWidth: '345px',
                  width: '100%',
                  //paddingInline: '24px',
                  marginBottom: '0px',
                }}
              >
                <Grid item sx={{ marginBottom: '20px' }}>
                  <img
                    src={SerialSuccess}
                    srcSet={`${SerialSuccess} 1x, ${SerialSuccess2} 2x, ${SerialSuccess3} 3x`}
                    style={{
                      width: '72.45px',
                      height: '72.45px',
                    }}
                    alt=""
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: '15px',
                      fontWeight: '600',
                      fontSize: '17px',
                      lineHeight: '23.22px',
                      letterSpacing: '-0.3px',

                      textAlign: 'center',
                    }}
                  >
                    {t('phoneSubmittedtitle')}
                  </Typography>

                  <Typography
                    color="#9CA0AB"
                    sx={{
                      fontWeight: '500',
                      fontSize: '12px',
                      lineHeight: '18px',
                      letterSpacing: '-0.3px',
                      textAlign: 'center',
                    }}
                  >
                    {t('phoneSubmittedSubtitle')}
                  </Typography>
                </Grid>
                <Grid item sx={{ marginTop: '20px', width: '100%' }}>
                  <PrimaryButton
                    sx={{ fontStyle: 'Semibold S' }}
                    onClick={() => {
                      setOutWorkHours(false);
                      //afterDialogWorkHours();
                    }}
                  >
                    {t('done')}
                  </PrimaryButton>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {' '}
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  marginTop: '30px',
                  //marginBottom: '20px',
                  width: '100%',
                }}
              >
                <Grid item>
                  <img
                    src={Headphones}
                    srcSet={`${Headphones} 1x, ${Headphones2} 2x, ${Headphones3} 3x`}
                    style={{
                      width: '110px',
                      height: '96px',
                    }}
                    alt="Phone Icon"
                  />
                </Grid>
                <Grid item sx={{ paddingBottom: '15px', marginTop: '20px' }}>
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: '600',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      textAlign: 'center',
                    }}
                  >
                    {t('outsideWorkhourstitle')}
                  </Typography>
                </Grid>
                <Grid item sx={{ marginBottom: '15px' }}>
                  <Typography
                    variant="caption"
                    color="#9CA0AB"
                    sx={{
                      fontWeight: '500',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      textAlign: 'center',
                    }}
                  >
                    {t('outsideWorkhoursSubtitle')}
                  </Typography>
                </Grid>
                <Grid item sx={{ marginBottom: '15px', width: '100%' }}>
                  <TextField
                    sx={{ minWidth: '250px', width: '100%' }}
                    defaultValue={phoneNumber}
                    label={t('phonenumber.label')}
                    fullWidth
                    value={phoneNumber}
                    onChange={(event) => {
                      setPhoneNumber(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item sx={{ marginBottom: '20px', width: '100%' }}>
                  <PrimaryButton
                    sx={{ fontStyle: 'Semibold S' }}
                    loadingLogic={true}
                    disabled={phoneNumber.length < 6}
                    onClick={async () => {
                      try {
                        setIsLoading(true);
                        const embeddedInsuranceService =
                          new EmbeddedInsuranceService();
                        await embeddedInsuranceService.notifyPhoneNumber(
                          phoneNumber
                        );
                        setSubmittedPhoneNumber(true);
                      } catch (err) {
                      } finally {
                        setIsLoading(false);
                      }
                    }}
                  >
                    {t('submit')}
                  </PrimaryButton>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    sx={{
                      marginBottom: '0px',
                      color: '#383A49',
                      padding: '0',
                      fontStyle: 'Semibold S',
                    }}
                    onClick={() => {
                      setOutWorkHours(false);
                      //afterDialogWorkHours();
                    }}
                  >
                    {t('cancel')}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CalculatorScreen;
