import { useTranslation, Trans } from 'react-i18next';
import { Grid, Link, Typography } from '@mui/material';
import { namespaces } from '@zenown-insurance/i18n-service';
import ReturnButton from '../return-button/ReturnButton';
import RVLogo from '../../assets/insurance/RV.png';
import RVLogo2 from '../../assets/insurance/RV@2x.png';
import RVLogo3 from '../../assets/insurance/RV@3x.png';
import { useEffect } from 'react';

export interface HowDoesWorksProps {
  onClose: () => void;
}

export function HowDoesWorks(props: any) {
  const { onClose, trackEvent, showPolicy } = props;

  const { t } = useTranslation(namespaces.pages.howPage);
  const { t: common } = useTranslation(namespaces.common);

  const renderSections = () => {
    const sectionTexts = HowDoesWorkParagraps.map(
      (paragraph: { title: string; description: string }, index: number) => {
        return (
          <Grid item key={index + ''}>
            <Typography sx={{ marginBottom: '20px' }} variant="h3">
              {index + 1 + '.  ' + t('paragraphs.' + paragraph.title)}
            </Typography>
            <Typography variant="subtitle1">
              <Trans
                i18nKey={'paragraphs.' + paragraph.description}
                components={[
                  <Link
                    style={{
                      cursor: 'pointer',
                      color: '#0179FF',
                      textDecoration: 'underline',
                    }}
                    underline="none"
                    onClick={() => showPolicy(true)}
                  >
                    {t('paragraphs.' + paragraph.description)}
                  </Link>,
                ]}
                t={t}
              ></Trans>
            </Typography>
          </Grid>
        );
      }
    );

    return sectionTexts;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ position: 'relative' }}>
      <ReturnButton
        onClick={() => {
          trackEvent({
            event: 'Button click',
            action: 'Back',
          });
          onClose();
        }}
      />
      {false && (
        <img
          src={RVLogo}
          srcSet={`${RVLogo} 1x, ${RVLogo2} 2x, ${RVLogo3} 3x`}
          style={{
            height: '22px',
            width: '56px',
            right: '0',
            top: '-45px',
            position: 'absolute',
          }}
          alt="RV Logo"
        />
      )}

      <div
        style={{
          height: 'auto',
          minWidth: '330px',
          overflowY: 'scroll',
          marginTop: '88px',
          marginBottom: '88px',
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ gap: '30px' }}
        >
          <Grid
            item
            container
            direction="column"
            justifyContent="space-between"
            spacing={1.5}
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h1" sx={{ marginBottom: '20px' }}>
                {t('title')}
              </Typography>
              <Typography variant="subtitle1">{t('description')}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            justifyContent="space-around"
            sx={{ gap: '30px' }}
            alignItems="flex-start"
          >
            {renderSections()}
          </Grid>
          <Grid item sx={{ marginBottom: '30px' }}>
            <Typography variant="subtitle1">
              <Trans
                i18nKey="footer"
                t={t}
                components={{
                  email: (
                    <Link href={'mailto:bikesupport@zenprotect.io'}>bikesupport@zenprotect.io</Link>
                  ),
                }}
              ></Trans>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="space-around"
          sx={{ gap: '15px' }}
          alignItems="flex-start"
        >
          <Grid item>
            <Typography variant="subtitle1">
              <Link
                underline="none"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  trackEvent({
                    event: 'Page Visit',
                    action: 'Privacy policy',
                  });
                  showPolicy(true);
                }}
              >
                {common('privacyPolicy')}
              </Link>
            </Typography>
          </Grid>{' '}
          {false && (
            <Grid item>
              <Typography variant="subtitle1">
                <Link
                  style={{ cursor: 'pointer' }}
                  underline="none"
                  onClick={() =>
                    trackEvent({
                      event: 'Page Visit',
                      action: 'Terms & Conditions',
                    })
                  }
                >
                  {common('termsAndCondition')}
                </Link>
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default HowDoesWorks;
const HowDoesWorkParagraps = [
  {
    title: 'p1.title',
    description: 'p1.description',
  },
  {
    title: 'p2.title',
    description: 'p2.description',
  },
  {
    title: 'p3.title',
    description: 'p3.description',
  },
  {
    title: 'p4.title',
    description: 'p4.description',
  },
  {
    title: 'p5.title',
    description: 'p5.description',
  },
  {
    title: 'p6.title',
    description: 'p6.description',
  },
  {
    title: 'p7.title',
    description: 'p7.description',
  },
];
