import { ApiServices } from './api-services';
// @ts-ignore
import { environment } from "@env-api/environment";
import { IDataResponse } from '@zenown-insurance/api-interfaces';


export class ZenownService {
  private _apiServices: ApiServices = new ApiServices(environment);

  public set apiServices(apiService: ApiServices){
    this._apiServices = apiService;
  }
  public get apiServices(){
    return this._apiServices ;
  }

  public doSomethingCall = async (): Promise<IDataResponse<any>> => {
    return this.apiServices.postRequest('', {}, {}, { authenticated: false });
  }

}
