import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';

const useStyles = makeStyles({
  selected: {
    color: '#0179FF',
  },
  unSelected: {
    color: '#CDCFD5',
  },
});

export const LanguageSection = ({ trackEvent }: any) => {
  const classes = useStyles();
  const { i18n } = useTranslation(namespaces.pages.uplaodPage);

  const [selectedLang, setSelectedLang] = useState(
    (
      localStorage.getItem('i18nextLng') ||
      localStorage.getItem('lang') ||
      'de'
    ).toLowerCase()
  );

  const isSelected = (lang: string) => {
    return selectedLang === lang;
  };

  const updateLang = (lang: string) => {
    trackEvent({
      event: 'Button click',
      action: lang,
    });
    setSelectedLang(lang);
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang.toLocaleLowerCase());
    localStorage.setItem('i18nextLng', lang);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="start"
      justifyContent="center"
      textAlign={'center'}
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',

        width: '100%',
      }}
    >
      <Grid item xs={12} textAlign={'center'}>
        <Button
          onClick={() => updateLang('de')}
          className={isSelected('de') ? classes.selected : classes.unSelected}
        >
          DE
        </Button>
        <Button
          onClick={() => updateLang('en')}
          className={isSelected('en') ? classes.selected : classes.unSelected}
        >
          EN
        </Button>
      </Grid>
    </Grid>
  );
};
